import "./AboutHero.css"
import Img from "../../Assets/Images/Rectangle 56.png"
import Img2 from "../../Assets/Images/Group 5.png"
import { useTranslation } from "react-i18next"
const AboutHero = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className='container-fluid about-hero'>
                <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xl-6 o1'>
                        <div className={i18n.language == "ar" ? 'text text-ar' : "text"}>
                            <h2>{t("DRIVEN-BY") + " " + t("SOLUTIONS")} </h2>
                            <p >
                                <span>{t("Customer-focused")}: </span>
                                {t("We-are-driven")}</p>
                            <p >
                                <span>{t("Problem-solving")}:</span>
                                {t("We-are-determined")}
                            </p>
                            <p >
                                <span>{t("Expertise")} :</span>
                                {t("We-have-the")}
                            </p>
                            <p >
                                <span>{t("Adaptability")}:</span>
                                {t("We-are-willing")}
                            </p>


                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 col-xl-6 flex-aswad-center o2'>
                        <div className="img ">
                            <img src={Img} />
                            <img src={Img2} className="img2" />
                        </div>
                        <div className="flex-aswad-center">
                            <div className={i18n.language == "ar" ? "flex-aswad-col flex-aswad-col-ar" : "flex-aswad-col"}>
                                <h6 className={i18n.language == "ar" ? "m-0 h6-ar" : "m-0"}>{t("over")}</h6>
                                <span className="m-negative">500</span>
                            </div>
                            <div className="flex-aswad-col">
                                <h3 className={i18n.language == "ar" ? "m-0 h6-ar" : "m-0"}>{t("projects")}</h3>
                                <h4 className={i18n.language == "ar" ? " h4-ar" : ""}>{t("around")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHero