import "./ContactUs.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/90.png"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
const ContactUs = () => {
    const { t, i18n } = useTranslation();
    let media = window.matchMedia("(max-width:767px)")
    let ipad = window.matchMedia("(min-device-width : 768px) and (max-device-width : 1024px)")
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [message, setMessage] = useState()
    const handleSubmit = () => {
        axios.post("https://trial.trendsbymidas.com/api/send_email/contact_us", {
            name,
            email,
            phone,
            message
        })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <Header />
            <div className="container-fluid contact-us">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-6 flex-aswad-row">
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("CALL")}</h2>
                            <p>1888886 </p>
                        </div>
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("ADDRESS")}</h2>
                            <p>{t("Farwaniya-Al-Dajeej")} <br />
                                {t("Al-Sayer-Complex-Kuwait")} </p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        <h2 className={i18n.language == "ar" ? "h2-ar" : ""}>{t("LOCATION")}</h2>
                        <div className="img">
                            <img src={Img} />
                        </div>
                    </div>
                </div>
                <div className="row second-row">
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        {media.matches == true || ipad.matches == true ? <></> : <textarea
                            className={i18n.language == "ar" ? "input-ar" : ""}
                            onChange={(e) => setMessage(e.target.value)} placeholder={t("write-here-your-message")}></textarea>}
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6">
                        <h2 className={i18n.language == "ar" ? "h2-form h2-form-ar" : "h2-form"}>{t("SEND-A-MESSAGE")}</h2>
                        <form>
                            <input
                                className={i18n.language == "ar" ? "input-ar" : ""}
                                type="text" placeholder={t("your-name")} onChange={(e) => setName(e.target.value)} />
                            <input
                                className={i18n.language == "ar" ? "input-ar" : ""}
                                type="email" placeholder={t("your-email")} onChange={(e) => setEmail(e.target.value)} />
                            <input
                                className={i18n.language == "ar" ? "input-ar" : ""}
                                type="text" placeholder={t("contact-number")} onChange={(e) => setPhone(e.target.value)} />
                            {media.matches == true || ipad.matches == true ? <textarea
                                className={i18n.language == "ar" ? "input-ar" : ""}
                                onChange={(e) => setMessage(e.target.value)} placeholder={t("write-here-your-message")}></textarea> : <></>}
                            <div className="flex-sm-center">
                                <a
                                    className={i18n.language == "ar" ? "input-ar" : ""}
                                    onClick={handleSubmit}>{t("send")}</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs