import "./Department.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/56.png"
import Dots from "../../Assets/Images/Layer_1.png"
import Img2 from "../../Assets/Images/Rectangle 37.png"
import Img3 from "../../Assets/Images/Rectangle 39.png"
import Dots2 from "../../Assets/Images/Layer_2.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const Department = () => {
    const { t, i18n } = useTranslation();
    let media = window.matchMedia("(max-width:767px)")
    let ipad = window.matchMedia("( (min-device-width : 768px) and (max-device-width : 1023px))")
    return (
        <>
            <Header />
            <div className="container-fluid department">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="images">
                            <img src={Img} className="archi" />
                            {media.matches == true || ipad.matches == true ? <img src={Dots2} className="dots" /> : <img src={Dots} className="dots " />}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("DESIGN-DEPARTMENT")}</h2>
                            <p>
                                {t("The-Trends")}
                            </p>
                            <div className="flex-aswad-end">
                                <Link to="/Projects"><h6>{t("projectss")}</h6></Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                                    <path d="M1 30L16 15.5L1 1" stroke="#B31B1B" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid department">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-2">
                        <div className="images images-2">
                            <img src={Img2} className="archi" />
                            {media.matches == true || ipad.matches == true ? <img src={Dots2} className="dots" /> : <img src={Dots} className="dots dots-2" />}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 order-1">
                        <div className={i18n.language == "ar" ? "text text-2 text-ar" : "text text-2"}>
                            <h2>{t("FITOUT-DEPARTMENT")}</h2>
                            <p>
                                {t("Trends-successfully")}
                            </p>
                            <div className="flex-aswad-end">
                                <Link to="/Projects"><h6>{t("projectss")}</h6></Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                                    <path d="M1 30L16 15.5L1 1" stroke="#B31B1B" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid department last-department">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="images">
                            <img src={Img3} className="archi" />
                            {media.matches == true || ipad.matches == true ? <img src={Dots2} className="dots" /> : <img src={Dots} className="dots" />}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className={i18n.language == "ar" ? "text text-ar" : "text"}>
                            <h2>{t("FINISHING-MATERIALS")}</h2>
                            <p>
                                {t("represents-many")}
                            </p>
                            <div className="flex-aswad-end">
                                <Link to="/Projects"><h6>{t("projectss")}</h6></Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="31" viewBox="0 0 17 31" fill="none">
                                    <path d="M1 30L16 15.5L1 1" stroke="#B31B1B" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Department