import "./Projects.css"
import { Header, Footer } from "../../Sections"
import Img from "../../Assets/Images/70.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"
import axios from "axios";
const Projects = () => {
    const { t, i18n } = useTranslation();
    let slideRef = useRef()
    let media = window.matchMedia("(max-width:767px)")
    const lis = document.querySelectorAll("li")
    const [projects, setProjects] = useState()
    const [singleProject, setSingleProject] = useState()
    const [categories, setCategories] = useState()
    const [uuid, setUuid] = useState()
    const [all, setAll] = useState(true)
    useEffect(() => {
        axios.get("https://trial.trendsbymidas.com/api/categories/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        }
        )
            .then(res => {
                console.log(res)
                setCategories(res.data.data.categories)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    const handleCategories = (uuid, e) => {
        setAll(false)
        lis.forEach((li) => {
            li.classList.remove("active")
        })
        e.target.classList.toggle("active")
        setUuid(uuid)
    }
    const handleAll = () => {
        setAll(true)
        lis.forEach((li) => {
            li.classList.remove("active")
        })
        setUuid(null)
    }
    useEffect(() => {

        axios.get(`https://trial.trendsbymidas.com/api/projects/${all == true ? `all` : `all? category_uuid=${uuid}`}`,
            {
                headers: {
                    "Accept-Language": i18n.language
                }
            })
            .then(res => {
                setProjects(res.data.data.projects)
            })
            .catch(err => {
                console.log(err)
            })
    }, [uuid])
    const handleSingleProject = (uuid) => {
        axios.post("https://trial.trendsbymidas.com/api/show/project", {
            project_uuid: uuid
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setSingleProject(res.data.data.project)
            })
            .catch(err => {
                console.log(err)
            })
    }


    return (
        <>
            <Header />
            <div className="container-fluid projects">
                <ul>
                    <li className={all == true && "active"} onClick={() => handleAll()}>All</li>
                    {categories && categories.map(item => (
                        <li value={item.uuid} key={item.uuid} onClick={(e) => handleCategories(item.uuid, e)}>{item.title}</li>
                    ))}

                </ul>
                <div className="row row-cols-2 row-cols-xl-3 ">
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-fullscreen ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="next" onClick={() => slideRef.current.swiper.slideNext()}>
                                        <IoIosArrowForward size={media.matches == true ? 20 : 60} />
                                    </div>
                                    <div className="prev" onClick={() => slideRef.current.swiper.slidePrev()}>
                                        <IoIosArrowBack size={media.matches == true ? 20 : 60} />
                                    </div>
                                    <div className="img-modal-body flex-aswad-center">
                                        <Swiper
                                            ref={slideRef}
                                            spaceBetween={30}
                                            effect={'fade'}
                                            autoplay={{
                                                delay: 4500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[EffectFade, Navigation, Pagination, Autoplay]}
                                            className="mySwiper"
                                        >
                                            {singleProject && singleProject.gallery.map(item => (
                                                <SwiperSlide>
                                                    <img src={item} />
                                                </SwiperSlide>
                                            ))}


                                        </Swiper>
                                    </div>
                                    <div className="flex-aswad-between">
                                        <p>{singleProject && singleProject.title}</p>
                                        <p>{singleProject && singleProject.description} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {projects && projects.map(item => (
                        <div className="col" key={item.uuid}>
                            <button type="button" className="btn btn-primary btn-modal-projects" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleSingleProject(item.uuid)}>
                                <img src={item.image} />
                            </button>
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Projects