import "./Hero.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next"
const Hero = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div id='hero' className={"container-fluid hero px-0 m-0" + " " + props.page}>
                <Swiper
                    spaceBetween={30}
                    effect={'fade'}
                    autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[EffectFade, Navigation, Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="img-slide-one">
                            <h2 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("DRIVEN-BY")}</h2>
                            <div className="flex-aswad-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="193" height="70" viewBox="0 0 193 70" fill="none">
                                    <path d="M1.67 3.34C2.59232 3.34 3.34 2.59232 3.34 1.67C3.34 0.747684 2.59232 0 1.67 0C0.747684 0 0 0.747684 0 1.67C0 2.59232 0.747684 3.34 1.67 3.34Z" fill="white" />
                                    <path d="M1.67 16.5701C0.75 16.5701 0 17.3201 0 18.2401C0 19.1601 0.75 19.9101 1.67 19.9101C2.59 19.9101 3.34 19.1601 3.34 18.2401C3.34 17.3201 2.59 16.5701 1.67 16.5701Z" fill="white" />
                                    <path d="M1.67 33.1499C0.75 33.1499 0 33.8999 0 34.8199C0 35.7399 0.75 36.4899 1.67 36.4899C2.59 36.4899 3.34 35.7399 3.34 34.8199C3.34 33.8999 2.59 33.1499 1.67 33.1499Z" fill="white" />
                                    <path d="M1.67 49.72C0.75 49.72 0 50.47 0 51.39C0 52.31 0.75 53.06 1.67 53.06C2.59 53.06 3.34 52.31 3.34 51.39C3.34 50.47 2.59 49.72 1.67 49.72Z" fill="white" />
                                    <path d="M1.67 69.64C2.59232 69.64 3.34 68.8924 3.34 67.97C3.34 67.0477 2.59232 66.3 1.67 66.3C0.747684 66.3 0 67.0477 0 67.97C0 68.8924 0.747684 69.64 1.67 69.64Z" fill="white" />
                                    <path d="M17.4102 3.34C18.3326 3.34 19.0802 2.59232 19.0802 1.67C19.0802 0.747684 18.3326 0 17.4102 0C16.4879 0 15.7402 0.747684 15.7402 1.67C15.7402 2.59232 16.4879 3.34 17.4102 3.34Z" fill="white" />
                                    <path d="M9.54012 7.87012C8.62012 7.87012 7.87012 8.62012 7.87012 9.54012C7.87012 10.4601 8.62012 11.2101 9.54012 11.2101C10.4601 11.2101 11.2101 10.4601 11.2101 9.54012C11.2101 8.62012 10.4601 7.87012 9.54012 7.87012Z" fill="white" />
                                    <path d="M17.4102 16.5701C16.4902 16.5701 15.7402 17.3201 15.7402 18.2401C15.7402 19.1601 16.4902 19.9101 17.4102 19.9101C18.3302 19.9101 19.0802 19.1601 19.0802 18.2401C19.0802 17.3201 18.3302 16.5701 17.4102 16.5701Z" fill="white" />
                                    <path d="M9.54012 24.4399C8.62012 24.4399 7.87012 25.1899 7.87012 26.1099C7.87012 27.0299 8.62012 27.7799 9.54012 27.7799C10.4601 27.7799 11.2101 27.0299 11.2101 26.1099C11.2101 25.1899 10.4601 24.4399 9.54012 24.4399Z" fill="white" />
                                    <path d="M17.4102 33.1499C16.4902 33.1499 15.7402 33.8999 15.7402 34.8199C15.7402 35.7399 16.4902 36.4899 17.4102 36.4899C18.3302 36.4899 19.0802 35.7399 19.0802 34.8199C19.0802 33.8999 18.3302 33.1499 17.4102 33.1499Z" fill="white" />
                                    <path d="M9.54012 41.02C8.62012 41.02 7.87012 41.77 7.87012 42.69C7.87012 43.61 8.62012 44.36 9.54012 44.36C10.4601 44.36 11.2101 43.61 11.2101 42.69C11.2101 41.77 10.4601 41.02 9.54012 41.02Z" fill="white" />
                                    <path d="M17.4102 49.72C16.4902 49.72 15.7402 50.47 15.7402 51.39C15.7402 52.31 16.4902 53.06 17.4102 53.06C18.3302 53.06 19.0802 52.31 19.0802 51.39C19.0802 50.47 18.3302 49.72 17.4102 49.72Z" fill="white" />
                                    <path d="M9.54012 57.5901C8.62012 57.5901 7.87012 58.3401 7.87012 59.2601C7.87012 60.1801 8.62012 60.9301 9.54012 60.9301C10.4601 60.9301 11.2101 60.1801 11.2101 59.2601C11.2101 58.3401 10.4601 57.5901 9.54012 57.5901Z" fill="white" />
                                    <path d="M17.4102 69.64C18.3326 69.64 19.0802 68.8924 19.0802 67.97C19.0802 67.0477 18.3326 66.3 17.4102 66.3C16.4879 66.3 15.7402 67.0477 15.7402 67.97C15.7402 68.8924 16.4879 69.64 17.4102 69.64Z" fill="white" />
                                    <path d="M33.1505 3.34C34.0728 3.34 34.8205 2.59232 34.8205 1.67C34.8205 0.747684 34.0728 0 33.1505 0C32.2282 0 31.4805 0.747684 31.4805 1.67C31.4805 2.59232 32.2282 3.34 33.1505 3.34Z" fill="white" />
                                    <path d="M25.2804 7.87012C24.3604 7.87012 23.6104 8.62012 23.6104 9.54012C23.6104 10.4601 24.3604 11.2101 25.2804 11.2101C26.2004 11.2101 26.9504 10.4601 26.9504 9.54012C26.9504 8.62012 26.2004 7.87012 25.2804 7.87012Z" fill="white" />
                                    <path d="M33.1505 16.5701C32.2305 16.5701 31.4805 17.3201 31.4805 18.2401C31.4805 19.1601 32.2305 19.9101 33.1505 19.9101C34.0705 19.9101 34.8205 19.1601 34.8205 18.2401C34.8205 17.3201 34.0705 16.5701 33.1505 16.5701Z" fill="white" />
                                    <path d="M25.2804 24.4399C24.3604 24.4399 23.6104 25.1899 23.6104 26.1099C23.6104 27.0299 24.3604 27.7799 25.2804 27.7799C26.2004 27.7799 26.9504 27.0299 26.9504 26.1099C26.9504 25.1899 26.2004 24.4399 25.2804 24.4399Z" fill="white" />
                                    <path d="M33.1505 33.1499C32.2305 33.1499 31.4805 33.8999 31.4805 34.8199C31.4805 35.7399 32.2305 36.4899 33.1505 36.4899C34.0705 36.4899 34.8205 35.7399 34.8205 34.8199C34.8205 33.8999 34.0705 33.1499 33.1505 33.1499Z" fill="white" />
                                    <path d="M25.2804 41.02C24.3604 41.02 23.6104 41.77 23.6104 42.69C23.6104 43.61 24.3604 44.36 25.2804 44.36C26.2004 44.36 26.9504 43.61 26.9504 42.69C26.9504 41.77 26.2004 41.02 25.2804 41.02Z" fill="white" />
                                    <path d="M33.1505 49.72C32.2305 49.72 31.4805 50.47 31.4805 51.39C31.4805 52.31 32.2305 53.06 33.1505 53.06C34.0705 53.06 34.8205 52.31 34.8205 51.39C34.8205 50.47 34.0705 49.72 33.1505 49.72Z" fill="white" />
                                    <path d="M25.2804 57.5901C24.3604 57.5901 23.6104 58.3401 23.6104 59.2601C23.6104 60.1801 24.3604 60.9301 25.2804 60.9301C26.2004 60.9301 26.9504 60.1801 26.9504 59.2601C26.9504 58.3401 26.2004 57.5901 25.2804 57.5901Z" fill="white" />
                                    <path d="M33.1505 69.64C34.0728 69.64 34.8205 68.8924 34.8205 67.97C34.8205 67.0477 34.0728 66.3 33.1505 66.3C32.2282 66.3 31.4805 67.0477 31.4805 67.97C31.4805 68.8924 32.2282 69.64 33.1505 69.64Z" fill="white" />
                                    <path d="M48.8897 0C47.9697 0 47.2197 0.75 47.2197 1.67C47.2197 2.59 47.9697 3.34 48.8897 3.34C49.8097 3.34 50.5597 2.59 50.5597 1.67C50.5597 0.75 49.8097 0 48.8897 0Z" fill="white" />
                                    <path d="M41.0196 7.87012C40.0996 7.87012 39.3496 8.62012 39.3496 9.54012C39.3496 10.4601 40.0996 11.2101 41.0196 11.2101C41.9396 11.2101 42.6896 10.4601 42.6896 9.54012C42.6896 8.62012 41.9396 7.87012 41.0196 7.87012Z" fill="white" />
                                    <path d="M48.8897 16.5701C47.9697 16.5701 47.2197 17.3201 47.2197 18.2401C47.2197 19.1601 47.9697 19.9101 48.8897 19.9101C49.8097 19.9101 50.5597 19.1601 50.5597 18.2401C50.5597 17.3201 49.8097 16.5701 48.8897 16.5701Z" fill="white" />
                                    <path d="M41.0196 24.4399C40.0996 24.4399 39.3496 25.1899 39.3496 26.1099C39.3496 27.0299 40.0996 27.7799 41.0196 27.7799C41.9396 27.7799 42.6896 27.0299 42.6896 26.1099C42.6896 25.1899 41.9396 24.4399 41.0196 24.4399Z" fill="white" />
                                    <path d="M48.8897 33.1499C47.9697 33.1499 47.2197 33.8999 47.2197 34.8199C47.2197 35.7399 47.9697 36.4899 48.8897 36.4899C49.8097 36.4899 50.5597 35.7399 50.5597 34.8199C50.5597 33.8999 49.8097 33.1499 48.8897 33.1499Z" fill="white" />
                                    <path d="M41.0196 41.02C40.0996 41.02 39.3496 41.77 39.3496 42.69C39.3496 43.61 40.0996 44.36 41.0196 44.36C41.9396 44.36 42.6896 43.61 42.6896 42.69C42.6896 41.77 41.9396 41.02 41.0196 41.02Z" fill="white" />
                                    <path d="M48.8897 49.72C47.9697 49.72 47.2197 50.47 47.2197 51.39C47.2197 52.31 47.9697 53.06 48.8897 53.06C49.8097 53.06 50.5597 52.31 50.5597 51.39C50.5597 50.47 49.8097 49.72 48.8897 49.72Z" fill="white" />
                                    <path d="M41.0196 57.5901C40.0996 57.5901 39.3496 58.3401 39.3496 59.2601C39.3496 60.1801 40.0996 60.9301 41.0196 60.9301C41.9396 60.9301 42.6896 60.1801 42.6896 59.2601C42.6896 58.3401 41.9396 57.5901 41.0196 57.5901Z" fill="white" />
                                    <path d="M48.8897 66.3C47.9697 66.3 47.2197 67.05 47.2197 67.97C47.2197 68.89 47.9697 69.64 48.8897 69.64C49.8097 69.64 50.5597 68.89 50.5597 67.97C50.5597 67.05 49.8097 66.3 48.8897 66.3Z" fill="white" />
                                    <path d="M64.6202 0C63.7002 0 62.9502 0.75 62.9502 1.67C62.9502 2.59 63.7002 3.34 64.6202 3.34C65.5402 3.34 66.2902 2.59 66.2902 1.67C66.2902 0.75 65.5402 0 64.6202 0Z" fill="white" />
                                    <path d="M56.7501 7.87012C55.8301 7.87012 55.0801 8.62012 55.0801 9.54012C55.0801 10.4601 55.8301 11.2101 56.7501 11.2101C57.6701 11.2101 58.4201 10.4601 58.4201 9.54012C58.4201 8.62012 57.6701 7.87012 56.7501 7.87012Z" fill="white" />
                                    <path d="M64.6202 16.5701C63.7002 16.5701 62.9502 17.3201 62.9502 18.2401C62.9502 19.1601 63.7002 19.9101 64.6202 19.9101C65.5402 19.9101 66.2902 19.1601 66.2902 18.2401C66.2902 17.3201 65.5402 16.5701 64.6202 16.5701Z" fill="white" />
                                    <path d="M56.7501 24.4399C55.8301 24.4399 55.0801 25.1899 55.0801 26.1099C55.0801 27.0299 55.8301 27.7799 56.7501 27.7799C57.6701 27.7799 58.4201 27.0299 58.4201 26.1099C58.4201 25.1899 57.6701 24.4399 56.7501 24.4399Z" fill="white" />
                                    <path d="M64.6202 33.1499C63.7002 33.1499 62.9502 33.8999 62.9502 34.8199C62.9502 35.7399 63.7002 36.4899 64.6202 36.4899C65.5402 36.4899 66.2902 35.7399 66.2902 34.8199C66.2902 33.8999 65.5402 33.1499 64.6202 33.1499Z" fill="white" />
                                    <path d="M56.7501 41.02C55.8301 41.02 55.0801 41.77 55.0801 42.69C55.0801 43.61 55.8301 44.36 56.7501 44.36C57.6701 44.36 58.4201 43.61 58.4201 42.69C58.4201 41.77 57.6701 41.02 56.7501 41.02Z" fill="white" />
                                    <path d="M64.6202 49.72C63.7002 49.72 62.9502 50.47 62.9502 51.39C62.9502 52.31 63.7002 53.06 64.6202 53.06C65.5402 53.06 66.2902 52.31 66.2902 51.39C66.2902 50.47 65.5402 49.72 64.6202 49.72Z" fill="white" />
                                    <path d="M56.7501 57.5901C55.8301 57.5901 55.0801 58.3401 55.0801 59.2601C55.0801 60.1801 55.8301 60.9301 56.7501 60.9301C57.6701 60.9301 58.4201 60.1801 58.4201 59.2601C58.4201 58.3401 57.6701 57.5901 56.7501 57.5901Z" fill="white" />
                                    <path d="M64.6202 66.3C63.7002 66.3 62.9502 67.05 62.9502 67.97C62.9502 68.89 63.7002 69.64 64.6202 69.64C65.5402 69.64 66.2902 68.89 66.2902 67.97C66.2902 67.05 65.5402 66.3 64.6202 66.3Z" fill="white" />
                                    <path d="M80.3604 0C79.4404 0 78.6904 0.75 78.6904 1.67C78.6904 2.59 79.4404 3.34 80.3604 3.34C81.2804 3.34 82.0304 2.59 82.0304 1.67C82.0304 0.75 81.2804 0 80.3604 0Z" fill="white" />
                                    <path d="M72.4903 7.87012C71.5703 7.87012 70.8203 8.62012 70.8203 9.54012C70.8203 10.4601 71.5703 11.2101 72.4903 11.2101C73.4103 11.2101 74.1603 10.4601 74.1603 9.54012C74.1603 8.62012 73.4103 7.87012 72.4903 7.87012Z" fill="white" />
                                    <path d="M80.3604 16.5701C79.4404 16.5701 78.6904 17.3201 78.6904 18.2401C78.6904 19.1601 79.4404 19.9101 80.3604 19.9101C81.2804 19.9101 82.0304 19.1601 82.0304 18.2401C82.0304 17.3201 81.2804 16.5701 80.3604 16.5701Z" fill="white" />
                                    <path d="M72.4903 24.4399C71.5703 24.4399 70.8203 25.1899 70.8203 26.1099C70.8203 27.0299 71.5703 27.7799 72.4903 27.7799C73.4103 27.7799 74.1603 27.0299 74.1603 26.1099C74.1603 25.1899 73.4103 24.4399 72.4903 24.4399Z" fill="white" />
                                    <path d="M80.3604 33.1499C79.4404 33.1499 78.6904 33.8999 78.6904 34.8199C78.6904 35.7399 79.4404 36.4899 80.3604 36.4899C81.2804 36.4899 82.0304 35.7399 82.0304 34.8199C82.0304 33.8999 81.2804 33.1499 80.3604 33.1499Z" fill="white" />
                                    <path d="M72.4903 41.02C71.5703 41.02 70.8203 41.77 70.8203 42.69C70.8203 43.61 71.5703 44.36 72.4903 44.36C73.4103 44.36 74.1603 43.61 74.1603 42.69C74.1603 41.77 73.4103 41.02 72.4903 41.02Z" fill="white" />
                                    <path d="M80.3604 49.72C79.4404 49.72 78.6904 50.47 78.6904 51.39C78.6904 52.31 79.4404 53.06 80.3604 53.06C81.2804 53.06 82.0304 52.31 82.0304 51.39C82.0304 50.47 81.2804 49.72 80.3604 49.72Z" fill="white" />
                                    <path d="M72.4903 57.5901C71.5703 57.5901 70.8203 58.3401 70.8203 59.2601C70.8203 60.1801 71.5703 60.9301 72.4903 60.9301C73.4103 60.9301 74.1603 60.1801 74.1603 59.2601C74.1603 58.3401 73.4103 57.5901 72.4903 57.5901Z" fill="white" />
                                    <path d="M80.3604 66.3C79.4404 66.3 78.6904 67.05 78.6904 67.97C78.6904 68.89 79.4404 69.64 80.3604 69.64C81.2804 69.64 82.0304 68.89 82.0304 67.97C82.0304 67.05 81.2804 66.3 80.3604 66.3Z" fill="white" />
                                    <path d="M96.0997 0C95.1797 0 94.4297 0.75 94.4297 1.67C94.4297 2.59 95.1797 3.34 96.0997 3.34C97.0197 3.34 97.7697 2.59 97.7697 1.67C97.7697 0.75 97.0197 0 96.0997 0Z" fill="white" />
                                    <path d="M88.2296 7.87012C87.4196 7.87012 86.7496 8.44012 86.5896 9.21012C86.5696 9.32012 86.5596 9.43012 86.5596 9.55012C86.5596 9.67012 86.5696 9.78012 86.5896 9.89012C86.7496 10.6501 87.4196 11.2301 88.2296 11.2301C89.1496 11.2301 89.8996 10.4801 89.8996 9.56012C89.8996 8.64012 89.1496 7.89012 88.2296 7.89012V7.87012Z" fill="white" />
                                    <path d="M96.0997 16.5701C95.1797 16.5701 94.4297 17.3201 94.4297 18.2401C94.4297 19.1601 95.1797 19.9101 96.0997 19.9101C97.0197 19.9101 97.7697 19.1601 97.7697 18.2401C97.7697 17.3201 97.0197 16.5701 96.0997 16.5701Z" fill="white" />
                                    <path d="M88.2296 24.4399C87.4196 24.4399 86.7496 25.0099 86.5896 25.7799C86.5696 25.8899 86.5596 25.9999 86.5596 26.1199C86.5596 26.2399 86.5696 26.3499 86.5896 26.4599C86.7496 27.2199 87.4196 27.7999 88.2296 27.7999C89.1496 27.7999 89.8996 27.0499 89.8996 26.1299C89.8996 25.2099 89.1496 24.4599 88.2296 24.4599V24.4399Z" fill="white" />
                                    <path d="M96.0997 33.1499C95.1797 33.1499 94.4297 33.8999 94.4297 34.8199C94.4297 35.7399 95.1797 36.4899 96.0997 36.4899C97.0197 36.4899 97.7697 35.7399 97.7697 34.8199C97.7697 33.8999 97.0197 33.1499 96.0997 33.1499Z" fill="white" />
                                    <path d="M88.2296 41.02C87.4196 41.02 86.7496 41.59 86.5896 42.36C86.5696 42.47 86.5596 42.58 86.5596 42.7C86.5596 42.82 86.5696 42.93 86.5896 43.04C86.7496 43.8 87.4196 44.38 88.2296 44.38C89.1496 44.38 89.8996 43.63 89.8996 42.71C89.8996 41.79 89.1496 41.04 88.2296 41.04V41.02Z" fill="white" />
                                    <path d="M96.0997 49.72C95.1797 49.72 94.4297 50.47 94.4297 51.39C94.4297 52.31 95.1797 53.06 96.0997 53.06C97.0197 53.06 97.7697 52.31 97.7697 51.39C97.7697 50.47 97.0197 49.72 96.0997 49.72Z" fill="white" />
                                    <path d="M88.2296 57.5901C87.4196 57.5901 86.7496 58.1601 86.5896 58.9301C86.5696 59.0401 86.5596 59.1501 86.5596 59.2701C86.5596 59.3901 86.5696 59.5001 86.5896 59.6101C86.7496 60.3701 87.4196 60.9501 88.2296 60.9501C89.1496 60.9501 89.8996 60.2001 89.8996 59.2801C89.8996 58.3601 89.1496 57.6101 88.2296 57.6101V57.5901Z" fill="white" />
                                    <path d="M96.0997 66.3C95.1797 66.3 94.4297 67.05 94.4297 67.97C94.4297 68.89 95.1797 69.64 96.0997 69.64C97.0197 69.64 97.7697 68.89 97.7697 67.97C97.7697 67.05 97.0197 66.3 96.0997 66.3Z" fill="white" />
                                    <path d="M111.83 0C110.91 0 110.16 0.75 110.16 1.67C110.16 2.59 110.91 3.34 111.83 3.34C112.75 3.34 113.5 2.59 113.5 1.67C113.5 0.75 112.75 0 111.83 0Z" fill="white" />
                                    <path d="M103.97 7.87012C103.05 7.87012 102.3 8.62012 102.3 9.54012C102.3 10.4601 103.05 11.2101 103.97 11.2101C104.89 11.2101 105.64 10.4601 105.64 9.54012C105.64 8.62012 104.89 7.87012 103.97 7.87012Z" fill="white" />
                                    <path d="M111.83 16.5701C110.91 16.5701 110.16 17.3201 110.16 18.2401C110.16 19.1601 110.91 19.9101 111.83 19.9101C112.75 19.9101 113.5 19.1601 113.5 18.2401C113.5 17.3201 112.75 16.5701 111.83 16.5701Z" fill="white" />
                                    <path d="M103.97 24.4399C103.05 24.4399 102.3 25.1899 102.3 26.1099C102.3 27.0299 103.05 27.7799 103.97 27.7799C104.89 27.7799 105.64 27.0299 105.64 26.1099C105.64 25.1899 104.89 24.4399 103.97 24.4399Z" fill="white" />
                                    <path d="M111.83 33.1499C110.91 33.1499 110.16 33.8999 110.16 34.8199C110.16 35.7399 110.91 36.4899 111.83 36.4899C112.75 36.4899 113.5 35.7399 113.5 34.8199C113.5 33.8999 112.75 33.1499 111.83 33.1499Z" fill="white" />
                                    <path d="M103.97 41.02C103.05 41.02 102.3 41.77 102.3 42.69C102.3 43.61 103.05 44.36 103.97 44.36C104.89 44.36 105.64 43.61 105.64 42.69C105.64 41.77 104.89 41.02 103.97 41.02Z" fill="white" />
                                    <path d="M111.83 49.72C110.91 49.72 110.16 50.47 110.16 51.39C110.16 52.31 110.91 53.06 111.83 53.06C112.75 53.06 113.5 52.31 113.5 51.39C113.5 50.47 112.75 49.72 111.83 49.72Z" fill="white" />
                                    <path d="M103.97 57.5901C103.05 57.5901 102.3 58.3401 102.3 59.2601C102.3 60.1801 103.05 60.9301 103.97 60.9301C104.89 60.9301 105.64 60.1801 105.64 59.2601C105.64 58.3401 104.89 57.5901 103.97 57.5901Z" fill="white" />
                                    <path d="M111.83 66.3C110.91 66.3 110.16 67.05 110.16 67.97C110.16 68.89 110.91 69.64 111.83 69.64C112.75 69.64 113.5 68.89 113.5 67.97C113.5 67.05 112.75 66.3 111.83 66.3Z" fill="white" />
                                    <path d="M127.57 0C126.65 0 125.9 0.75 125.9 1.67C125.9 2.59 126.65 3.34 127.57 3.34C128.49 3.34 129.24 2.59 129.24 1.67C129.24 0.75 128.49 0 127.57 0Z" fill="white" />
                                    <path d="M119.7 7.87012C118.78 7.87012 118.03 8.62012 118.03 9.54012C118.03 10.4601 118.78 11.2101 119.7 11.2101C120.62 11.2101 121.37 10.4601 121.37 9.54012C121.37 8.62012 120.62 7.87012 119.7 7.87012Z" fill="white" />
                                    <path d="M127.57 16.5701C126.65 16.5701 125.9 17.3201 125.9 18.2401C125.9 19.1601 126.65 19.9101 127.57 19.9101C128.49 19.9101 129.24 19.1601 129.24 18.2401C129.24 17.3201 128.49 16.5701 127.57 16.5701Z" fill="white" />
                                    <path d="M119.7 24.4399C118.78 24.4399 118.03 25.1899 118.03 26.1099C118.03 27.0299 118.78 27.7799 119.7 27.7799C120.62 27.7799 121.37 27.0299 121.37 26.1099C121.37 25.1899 120.62 24.4399 119.7 24.4399Z" fill="white" />
                                    <path d="M127.57 33.1499C126.65 33.1499 125.9 33.8999 125.9 34.8199C125.9 35.7399 126.65 36.4899 127.57 36.4899C128.49 36.4899 129.24 35.7399 129.24 34.8199C129.24 33.8999 128.49 33.1499 127.57 33.1499Z" fill="white" />
                                    <path d="M119.7 41.02C118.78 41.02 118.03 41.77 118.03 42.69C118.03 43.61 118.78 44.36 119.7 44.36C120.62 44.36 121.37 43.61 121.37 42.69C121.37 41.77 120.62 41.02 119.7 41.02Z" fill="white" />
                                    <path d="M127.57 49.72C126.65 49.72 125.9 50.47 125.9 51.39C125.9 52.31 126.65 53.06 127.57 53.06C128.49 53.06 129.24 52.31 129.24 51.39C129.24 50.47 128.49 49.72 127.57 49.72Z" fill="white" />
                                    <path d="M119.7 57.5901C118.78 57.5901 118.03 58.3401 118.03 59.2601C118.03 60.1801 118.78 60.9301 119.7 60.9301C120.62 60.9301 121.37 60.1801 121.37 59.2601C121.37 58.3401 120.62 57.5901 119.7 57.5901Z" fill="white" />
                                    <path d="M127.57 66.3C126.65 66.3 125.9 67.05 125.9 67.97C125.9 68.89 126.65 69.64 127.57 69.64C128.49 69.64 129.24 68.89 129.24 67.97C129.24 67.05 128.49 66.3 127.57 66.3Z" fill="white" />
                                    <path d="M143.31 0C142.39 0 141.64 0.75 141.64 1.67C141.64 2.59 142.39 3.34 143.31 3.34C144.23 3.34 144.98 2.59 144.98 1.67C144.98 0.75 144.23 0 143.31 0Z" fill="white" />
                                    <path d="M135.44 7.87012C134.52 7.87012 133.77 8.62012 133.77 9.54012C133.77 10.4601 134.52 11.2101 135.44 11.2101C136.36 11.2101 137.11 10.4601 137.11 9.54012C137.11 8.62012 136.36 7.87012 135.44 7.87012Z" fill="white" />
                                    <path d="M143.31 16.5701C142.39 16.5701 141.64 17.3201 141.64 18.2401C141.64 19.1601 142.39 19.9101 143.31 19.9101C144.23 19.9101 144.98 19.1601 144.98 18.2401C144.98 17.3201 144.23 16.5701 143.31 16.5701Z" fill="white" />
                                    <path d="M135.44 24.4399C134.52 24.4399 133.77 25.1899 133.77 26.1099C133.77 27.0299 134.52 27.7799 135.44 27.7799C136.36 27.7799 137.11 27.0299 137.11 26.1099C137.11 25.1899 136.36 24.4399 135.44 24.4399Z" fill="white" />
                                    <path d="M143.31 33.1499C142.39 33.1499 141.64 33.8999 141.64 34.8199C141.64 35.7399 142.39 36.4899 143.31 36.4899C144.23 36.4899 144.98 35.7399 144.98 34.8199C144.98 33.8999 144.23 33.1499 143.31 33.1499Z" fill="white" />
                                    <path d="M135.44 41.02C134.52 41.02 133.77 41.77 133.77 42.69C133.77 43.61 134.52 44.36 135.44 44.36C136.36 44.36 137.11 43.61 137.11 42.69C137.11 41.77 136.36 41.02 135.44 41.02Z" fill="white" />
                                    <path d="M143.31 49.72C142.39 49.72 141.64 50.47 141.64 51.39C141.64 52.31 142.39 53.06 143.31 53.06C144.23 53.06 144.98 52.31 144.98 51.39C144.98 50.47 144.23 49.72 143.31 49.72Z" fill="white" />
                                    <path d="M135.44 57.5901C134.52 57.5901 133.77 58.3401 133.77 59.2601C133.77 60.1801 134.52 60.9301 135.44 60.9301C136.36 60.9301 137.11 60.1801 137.11 59.2601C137.11 58.3401 136.36 57.5901 135.44 57.5901Z" fill="white" />
                                    <path d="M143.31 66.3C142.39 66.3 141.64 67.05 141.64 67.97C141.64 68.89 142.39 69.64 143.31 69.64C144.23 69.64 144.98 68.89 144.98 67.97C144.98 67.05 144.23 66.3 143.31 66.3Z" fill="white" />
                                    <path d="M159.05 0C158.13 0 157.38 0.75 157.38 1.67C157.38 2.59 158.13 3.34 159.05 3.34C159.97 3.34 160.72 2.59 160.72 1.67C160.72 0.75 159.97 0 159.05 0Z" fill="white" />
                                    <path d="M151.18 7.87012C150.26 7.87012 149.51 8.62012 149.51 9.54012C149.51 10.4601 150.26 11.2101 151.18 11.2101C152.1 11.2101 152.85 10.4601 152.85 9.54012C152.85 8.62012 152.1 7.87012 151.18 7.87012Z" fill="white" />
                                    <path d="M159.05 16.5701C158.13 16.5701 157.38 17.3201 157.38 18.2401C157.38 19.1601 158.13 19.9101 159.05 19.9101C159.97 19.9101 160.72 19.1601 160.72 18.2401C160.72 17.3201 159.97 16.5701 159.05 16.5701Z" fill="white" />
                                    <path d="M151.18 24.4399C150.26 24.4399 149.51 25.1899 149.51 26.1099C149.51 27.0299 150.26 27.7799 151.18 27.7799C152.1 27.7799 152.85 27.0299 152.85 26.1099C152.85 25.1899 152.1 24.4399 151.18 24.4399Z" fill="white" />
                                    <path d="M159.05 33.1499C158.13 33.1499 157.38 33.8999 157.38 34.8199C157.38 35.7399 158.13 36.4899 159.05 36.4899C159.97 36.4899 160.72 35.7399 160.72 34.8199C160.72 33.8999 159.97 33.1499 159.05 33.1499Z" fill="white" />
                                    <path d="M151.18 41.02C150.26 41.02 149.51 41.77 149.51 42.69C149.51 43.61 150.26 44.36 151.18 44.36C152.1 44.36 152.85 43.61 152.85 42.69C152.85 41.77 152.1 41.02 151.18 41.02Z" fill="white" />
                                    <path d="M159.05 49.72C158.13 49.72 157.38 50.47 157.38 51.39C157.38 52.31 158.13 53.06 159.05 53.06C159.97 53.06 160.72 52.31 160.72 51.39C160.72 50.47 159.97 49.72 159.05 49.72Z" fill="white" />
                                    <path d="M151.18 57.5901C150.26 57.5901 149.51 58.3401 149.51 59.2601C149.51 60.1801 150.26 60.9301 151.18 60.9301C152.1 60.9301 152.85 60.1801 152.85 59.2601C152.85 58.3401 152.1 57.5901 151.18 57.5901Z" fill="white" />
                                    <path d="M159.05 66.3C158.13 66.3 157.38 67.05 157.38 67.97C157.38 68.89 158.13 69.64 159.05 69.64C159.97 69.64 160.72 68.89 160.72 67.97C160.72 67.05 159.97 66.3 159.05 66.3Z" fill="white" />
                                    <path d="M174.78 3.34C175.703 3.34 176.45 2.59232 176.45 1.67C176.45 0.747684 175.703 0 174.78 0C173.858 0 173.11 0.747684 173.11 1.67C173.11 2.59232 173.858 3.34 174.78 3.34Z" fill="white" />
                                    <path d="M166.91 7.87012C165.99 7.87012 165.24 8.62012 165.24 9.54012C165.24 10.4601 165.99 11.2101 166.91 11.2101C167.83 11.2101 168.58 10.4601 168.58 9.54012C168.58 8.62012 167.83 7.87012 166.91 7.87012Z" fill="white" />
                                    <path d="M174.78 16.5701C173.86 16.5701 173.11 17.3201 173.11 18.2401C173.11 19.1601 173.86 19.9101 174.78 19.9101C175.7 19.9101 176.45 19.1601 176.45 18.2401C176.45 17.3201 175.7 16.5701 174.78 16.5701Z" fill="white" />
                                    <path d="M166.91 24.4399C165.99 24.4399 165.24 25.1899 165.24 26.1099C165.24 27.0299 165.99 27.7799 166.91 27.7799C167.83 27.7799 168.58 27.0299 168.58 26.1099C168.58 25.1899 167.83 24.4399 166.91 24.4399Z" fill="white" />
                                    <path d="M174.78 33.1499C173.86 33.1499 173.11 33.8999 173.11 34.8199C173.11 35.7399 173.86 36.4899 174.78 36.4899C175.7 36.4899 176.45 35.7399 176.45 34.8199C176.45 33.8999 175.7 33.1499 174.78 33.1499Z" fill="white" />
                                    <path d="M166.91 41.02C165.99 41.02 165.24 41.77 165.24 42.69C165.24 43.61 165.99 44.36 166.91 44.36C167.83 44.36 168.58 43.61 168.58 42.69C168.58 41.77 167.83 41.02 166.91 41.02Z" fill="white" />
                                    <path d="M174.78 49.72C173.86 49.72 173.11 50.47 173.11 51.39C173.11 52.31 173.86 53.06 174.78 53.06C175.7 53.06 176.45 52.31 176.45 51.39C176.45 50.47 175.7 49.72 174.78 49.72Z" fill="white" />
                                    <path d="M166.91 57.5901C165.99 57.5901 165.24 58.3401 165.24 59.2601C165.24 60.1801 165.99 60.9301 166.91 60.9301C167.83 60.9301 168.58 60.1801 168.58 59.2601C168.58 58.3401 167.83 57.5901 166.91 57.5901Z" fill="white" />
                                    <path d="M174.78 69.64C175.703 69.64 176.45 68.8924 176.45 67.97C176.45 67.0477 175.703 66.3 174.78 66.3C173.858 66.3 173.11 67.0477 173.11 67.97C173.11 68.8924 173.858 69.64 174.78 69.64Z" fill="white" />
                                    <path d="M190.52 0C189.6 0 188.85 0.75 188.85 1.67C188.85 2.59 189.6 3.34 190.52 3.34C191.44 3.34 192.19 2.59 192.19 1.67C192.19 0.75 191.44 0 190.52 0Z" fill="white" />
                                    <path d="M182.65 7.87012C181.73 7.87012 180.98 8.62012 180.98 9.54012C180.98 10.4601 181.73 11.2101 182.65 11.2101C183.57 11.2101 184.32 10.4601 184.32 9.54012C184.32 8.62012 183.57 7.87012 182.65 7.87012Z" fill="white" />
                                    <path d="M190.52 16.5701C189.6 16.5701 188.85 17.3201 188.85 18.2401C188.85 19.1601 189.6 19.9101 190.52 19.9101C191.44 19.9101 192.19 19.1601 192.19 18.2401C192.19 17.3201 191.44 16.5701 190.52 16.5701Z" fill="white" />
                                    <path d="M182.65 24.4399C181.73 24.4399 180.98 25.1899 180.98 26.1099C180.98 27.0299 181.73 27.7799 182.65 27.7799C183.57 27.7799 184.32 27.0299 184.32 26.1099C184.32 25.1899 183.57 24.4399 182.65 24.4399Z" fill="white" />
                                    <path d="M190.52 33.1499C189.6 33.1499 188.85 33.8999 188.85 34.8199C188.85 35.7399 189.6 36.4899 190.52 36.4899C191.44 36.4899 192.19 35.7399 192.19 34.8199C192.19 33.8999 191.44 33.1499 190.52 33.1499Z" fill="white" />
                                    <path d="M182.65 41.02C181.73 41.02 180.98 41.77 180.98 42.69C180.98 43.61 181.73 44.36 182.65 44.36C183.57 44.36 184.32 43.61 184.32 42.69C184.32 41.77 183.57 41.02 182.65 41.02Z" fill="white" />
                                    <path d="M190.52 49.72C189.6 49.72 188.85 50.47 188.85 51.39C188.85 52.31 189.6 53.06 190.52 53.06C191.44 53.06 192.19 52.31 192.19 51.39C192.19 50.47 191.44 49.72 190.52 49.72Z" fill="white" />
                                    <path d="M182.65 57.5901C181.73 57.5901 180.98 58.3401 180.98 59.2601C180.98 60.1801 181.73 60.9301 182.65 60.9301C183.57 60.9301 184.32 60.1801 184.32 59.2601C184.32 58.3401 183.57 57.5901 182.65 57.5901Z" fill="white" />
                                    <path d="M190.52 66.3C189.6 66.3 188.85 67.05 188.85 67.97C188.85 68.89 189.6 69.64 190.52 69.64C191.44 69.64 192.19 68.89 192.19 67.97C192.19 67.05 191.44 66.3 190.52 66.3Z" fill="white" />
                                </svg>
                                <h4 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("SOLUTIONS")}</h4>
                            </div>
                            <span>MEEDAD</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="img-slide-two">
                            <h2 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("DRIVEN-BY")}</h2>
                            <div className="flex-aswad-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="193" height="70" viewBox="0 0 193 70" fill="none">
                                    <path d="M1.67 3.34C2.59232 3.34 3.34 2.59232 3.34 1.67C3.34 0.747684 2.59232 0 1.67 0C0.747684 0 0 0.747684 0 1.67C0 2.59232 0.747684 3.34 1.67 3.34Z" fill="white" />
                                    <path d="M1.67 16.5701C0.75 16.5701 0 17.3201 0 18.2401C0 19.1601 0.75 19.9101 1.67 19.9101C2.59 19.9101 3.34 19.1601 3.34 18.2401C3.34 17.3201 2.59 16.5701 1.67 16.5701Z" fill="white" />
                                    <path d="M1.67 33.1499C0.75 33.1499 0 33.8999 0 34.8199C0 35.7399 0.75 36.4899 1.67 36.4899C2.59 36.4899 3.34 35.7399 3.34 34.8199C3.34 33.8999 2.59 33.1499 1.67 33.1499Z" fill="white" />
                                    <path d="M1.67 49.72C0.75 49.72 0 50.47 0 51.39C0 52.31 0.75 53.06 1.67 53.06C2.59 53.06 3.34 52.31 3.34 51.39C3.34 50.47 2.59 49.72 1.67 49.72Z" fill="white" />
                                    <path d="M1.67 69.64C2.59232 69.64 3.34 68.8924 3.34 67.97C3.34 67.0477 2.59232 66.3 1.67 66.3C0.747684 66.3 0 67.0477 0 67.97C0 68.8924 0.747684 69.64 1.67 69.64Z" fill="white" />
                                    <path d="M17.4102 3.34C18.3326 3.34 19.0802 2.59232 19.0802 1.67C19.0802 0.747684 18.3326 0 17.4102 0C16.4879 0 15.7402 0.747684 15.7402 1.67C15.7402 2.59232 16.4879 3.34 17.4102 3.34Z" fill="white" />
                                    <path d="M9.54012 7.87012C8.62012 7.87012 7.87012 8.62012 7.87012 9.54012C7.87012 10.4601 8.62012 11.2101 9.54012 11.2101C10.4601 11.2101 11.2101 10.4601 11.2101 9.54012C11.2101 8.62012 10.4601 7.87012 9.54012 7.87012Z" fill="white" />
                                    <path d="M17.4102 16.5701C16.4902 16.5701 15.7402 17.3201 15.7402 18.2401C15.7402 19.1601 16.4902 19.9101 17.4102 19.9101C18.3302 19.9101 19.0802 19.1601 19.0802 18.2401C19.0802 17.3201 18.3302 16.5701 17.4102 16.5701Z" fill="white" />
                                    <path d="M9.54012 24.4399C8.62012 24.4399 7.87012 25.1899 7.87012 26.1099C7.87012 27.0299 8.62012 27.7799 9.54012 27.7799C10.4601 27.7799 11.2101 27.0299 11.2101 26.1099C11.2101 25.1899 10.4601 24.4399 9.54012 24.4399Z" fill="white" />
                                    <path d="M17.4102 33.1499C16.4902 33.1499 15.7402 33.8999 15.7402 34.8199C15.7402 35.7399 16.4902 36.4899 17.4102 36.4899C18.3302 36.4899 19.0802 35.7399 19.0802 34.8199C19.0802 33.8999 18.3302 33.1499 17.4102 33.1499Z" fill="white" />
                                    <path d="M9.54012 41.02C8.62012 41.02 7.87012 41.77 7.87012 42.69C7.87012 43.61 8.62012 44.36 9.54012 44.36C10.4601 44.36 11.2101 43.61 11.2101 42.69C11.2101 41.77 10.4601 41.02 9.54012 41.02Z" fill="white" />
                                    <path d="M17.4102 49.72C16.4902 49.72 15.7402 50.47 15.7402 51.39C15.7402 52.31 16.4902 53.06 17.4102 53.06C18.3302 53.06 19.0802 52.31 19.0802 51.39C19.0802 50.47 18.3302 49.72 17.4102 49.72Z" fill="white" />
                                    <path d="M9.54012 57.5901C8.62012 57.5901 7.87012 58.3401 7.87012 59.2601C7.87012 60.1801 8.62012 60.9301 9.54012 60.9301C10.4601 60.9301 11.2101 60.1801 11.2101 59.2601C11.2101 58.3401 10.4601 57.5901 9.54012 57.5901Z" fill="white" />
                                    <path d="M17.4102 69.64C18.3326 69.64 19.0802 68.8924 19.0802 67.97C19.0802 67.0477 18.3326 66.3 17.4102 66.3C16.4879 66.3 15.7402 67.0477 15.7402 67.97C15.7402 68.8924 16.4879 69.64 17.4102 69.64Z" fill="white" />
                                    <path d="M33.1505 3.34C34.0728 3.34 34.8205 2.59232 34.8205 1.67C34.8205 0.747684 34.0728 0 33.1505 0C32.2282 0 31.4805 0.747684 31.4805 1.67C31.4805 2.59232 32.2282 3.34 33.1505 3.34Z" fill="white" />
                                    <path d="M25.2804 7.87012C24.3604 7.87012 23.6104 8.62012 23.6104 9.54012C23.6104 10.4601 24.3604 11.2101 25.2804 11.2101C26.2004 11.2101 26.9504 10.4601 26.9504 9.54012C26.9504 8.62012 26.2004 7.87012 25.2804 7.87012Z" fill="white" />
                                    <path d="M33.1505 16.5701C32.2305 16.5701 31.4805 17.3201 31.4805 18.2401C31.4805 19.1601 32.2305 19.9101 33.1505 19.9101C34.0705 19.9101 34.8205 19.1601 34.8205 18.2401C34.8205 17.3201 34.0705 16.5701 33.1505 16.5701Z" fill="white" />
                                    <path d="M25.2804 24.4399C24.3604 24.4399 23.6104 25.1899 23.6104 26.1099C23.6104 27.0299 24.3604 27.7799 25.2804 27.7799C26.2004 27.7799 26.9504 27.0299 26.9504 26.1099C26.9504 25.1899 26.2004 24.4399 25.2804 24.4399Z" fill="white" />
                                    <path d="M33.1505 33.1499C32.2305 33.1499 31.4805 33.8999 31.4805 34.8199C31.4805 35.7399 32.2305 36.4899 33.1505 36.4899C34.0705 36.4899 34.8205 35.7399 34.8205 34.8199C34.8205 33.8999 34.0705 33.1499 33.1505 33.1499Z" fill="white" />
                                    <path d="M25.2804 41.02C24.3604 41.02 23.6104 41.77 23.6104 42.69C23.6104 43.61 24.3604 44.36 25.2804 44.36C26.2004 44.36 26.9504 43.61 26.9504 42.69C26.9504 41.77 26.2004 41.02 25.2804 41.02Z" fill="white" />
                                    <path d="M33.1505 49.72C32.2305 49.72 31.4805 50.47 31.4805 51.39C31.4805 52.31 32.2305 53.06 33.1505 53.06C34.0705 53.06 34.8205 52.31 34.8205 51.39C34.8205 50.47 34.0705 49.72 33.1505 49.72Z" fill="white" />
                                    <path d="M25.2804 57.5901C24.3604 57.5901 23.6104 58.3401 23.6104 59.2601C23.6104 60.1801 24.3604 60.9301 25.2804 60.9301C26.2004 60.9301 26.9504 60.1801 26.9504 59.2601C26.9504 58.3401 26.2004 57.5901 25.2804 57.5901Z" fill="white" />
                                    <path d="M33.1505 69.64C34.0728 69.64 34.8205 68.8924 34.8205 67.97C34.8205 67.0477 34.0728 66.3 33.1505 66.3C32.2282 66.3 31.4805 67.0477 31.4805 67.97C31.4805 68.8924 32.2282 69.64 33.1505 69.64Z" fill="white" />
                                    <path d="M48.8897 0C47.9697 0 47.2197 0.75 47.2197 1.67C47.2197 2.59 47.9697 3.34 48.8897 3.34C49.8097 3.34 50.5597 2.59 50.5597 1.67C50.5597 0.75 49.8097 0 48.8897 0Z" fill="white" />
                                    <path d="M41.0196 7.87012C40.0996 7.87012 39.3496 8.62012 39.3496 9.54012C39.3496 10.4601 40.0996 11.2101 41.0196 11.2101C41.9396 11.2101 42.6896 10.4601 42.6896 9.54012C42.6896 8.62012 41.9396 7.87012 41.0196 7.87012Z" fill="white" />
                                    <path d="M48.8897 16.5701C47.9697 16.5701 47.2197 17.3201 47.2197 18.2401C47.2197 19.1601 47.9697 19.9101 48.8897 19.9101C49.8097 19.9101 50.5597 19.1601 50.5597 18.2401C50.5597 17.3201 49.8097 16.5701 48.8897 16.5701Z" fill="white" />
                                    <path d="M41.0196 24.4399C40.0996 24.4399 39.3496 25.1899 39.3496 26.1099C39.3496 27.0299 40.0996 27.7799 41.0196 27.7799C41.9396 27.7799 42.6896 27.0299 42.6896 26.1099C42.6896 25.1899 41.9396 24.4399 41.0196 24.4399Z" fill="white" />
                                    <path d="M48.8897 33.1499C47.9697 33.1499 47.2197 33.8999 47.2197 34.8199C47.2197 35.7399 47.9697 36.4899 48.8897 36.4899C49.8097 36.4899 50.5597 35.7399 50.5597 34.8199C50.5597 33.8999 49.8097 33.1499 48.8897 33.1499Z" fill="white" />
                                    <path d="M41.0196 41.02C40.0996 41.02 39.3496 41.77 39.3496 42.69C39.3496 43.61 40.0996 44.36 41.0196 44.36C41.9396 44.36 42.6896 43.61 42.6896 42.69C42.6896 41.77 41.9396 41.02 41.0196 41.02Z" fill="white" />
                                    <path d="M48.8897 49.72C47.9697 49.72 47.2197 50.47 47.2197 51.39C47.2197 52.31 47.9697 53.06 48.8897 53.06C49.8097 53.06 50.5597 52.31 50.5597 51.39C50.5597 50.47 49.8097 49.72 48.8897 49.72Z" fill="white" />
                                    <path d="M41.0196 57.5901C40.0996 57.5901 39.3496 58.3401 39.3496 59.2601C39.3496 60.1801 40.0996 60.9301 41.0196 60.9301C41.9396 60.9301 42.6896 60.1801 42.6896 59.2601C42.6896 58.3401 41.9396 57.5901 41.0196 57.5901Z" fill="white" />
                                    <path d="M48.8897 66.3C47.9697 66.3 47.2197 67.05 47.2197 67.97C47.2197 68.89 47.9697 69.64 48.8897 69.64C49.8097 69.64 50.5597 68.89 50.5597 67.97C50.5597 67.05 49.8097 66.3 48.8897 66.3Z" fill="white" />
                                    <path d="M64.6202 0C63.7002 0 62.9502 0.75 62.9502 1.67C62.9502 2.59 63.7002 3.34 64.6202 3.34C65.5402 3.34 66.2902 2.59 66.2902 1.67C66.2902 0.75 65.5402 0 64.6202 0Z" fill="white" />
                                    <path d="M56.7501 7.87012C55.8301 7.87012 55.0801 8.62012 55.0801 9.54012C55.0801 10.4601 55.8301 11.2101 56.7501 11.2101C57.6701 11.2101 58.4201 10.4601 58.4201 9.54012C58.4201 8.62012 57.6701 7.87012 56.7501 7.87012Z" fill="white" />
                                    <path d="M64.6202 16.5701C63.7002 16.5701 62.9502 17.3201 62.9502 18.2401C62.9502 19.1601 63.7002 19.9101 64.6202 19.9101C65.5402 19.9101 66.2902 19.1601 66.2902 18.2401C66.2902 17.3201 65.5402 16.5701 64.6202 16.5701Z" fill="white" />
                                    <path d="M56.7501 24.4399C55.8301 24.4399 55.0801 25.1899 55.0801 26.1099C55.0801 27.0299 55.8301 27.7799 56.7501 27.7799C57.6701 27.7799 58.4201 27.0299 58.4201 26.1099C58.4201 25.1899 57.6701 24.4399 56.7501 24.4399Z" fill="white" />
                                    <path d="M64.6202 33.1499C63.7002 33.1499 62.9502 33.8999 62.9502 34.8199C62.9502 35.7399 63.7002 36.4899 64.6202 36.4899C65.5402 36.4899 66.2902 35.7399 66.2902 34.8199C66.2902 33.8999 65.5402 33.1499 64.6202 33.1499Z" fill="white" />
                                    <path d="M56.7501 41.02C55.8301 41.02 55.0801 41.77 55.0801 42.69C55.0801 43.61 55.8301 44.36 56.7501 44.36C57.6701 44.36 58.4201 43.61 58.4201 42.69C58.4201 41.77 57.6701 41.02 56.7501 41.02Z" fill="white" />
                                    <path d="M64.6202 49.72C63.7002 49.72 62.9502 50.47 62.9502 51.39C62.9502 52.31 63.7002 53.06 64.6202 53.06C65.5402 53.06 66.2902 52.31 66.2902 51.39C66.2902 50.47 65.5402 49.72 64.6202 49.72Z" fill="white" />
                                    <path d="M56.7501 57.5901C55.8301 57.5901 55.0801 58.3401 55.0801 59.2601C55.0801 60.1801 55.8301 60.9301 56.7501 60.9301C57.6701 60.9301 58.4201 60.1801 58.4201 59.2601C58.4201 58.3401 57.6701 57.5901 56.7501 57.5901Z" fill="white" />
                                    <path d="M64.6202 66.3C63.7002 66.3 62.9502 67.05 62.9502 67.97C62.9502 68.89 63.7002 69.64 64.6202 69.64C65.5402 69.64 66.2902 68.89 66.2902 67.97C66.2902 67.05 65.5402 66.3 64.6202 66.3Z" fill="white" />
                                    <path d="M80.3604 0C79.4404 0 78.6904 0.75 78.6904 1.67C78.6904 2.59 79.4404 3.34 80.3604 3.34C81.2804 3.34 82.0304 2.59 82.0304 1.67C82.0304 0.75 81.2804 0 80.3604 0Z" fill="white" />
                                    <path d="M72.4903 7.87012C71.5703 7.87012 70.8203 8.62012 70.8203 9.54012C70.8203 10.4601 71.5703 11.2101 72.4903 11.2101C73.4103 11.2101 74.1603 10.4601 74.1603 9.54012C74.1603 8.62012 73.4103 7.87012 72.4903 7.87012Z" fill="white" />
                                    <path d="M80.3604 16.5701C79.4404 16.5701 78.6904 17.3201 78.6904 18.2401C78.6904 19.1601 79.4404 19.9101 80.3604 19.9101C81.2804 19.9101 82.0304 19.1601 82.0304 18.2401C82.0304 17.3201 81.2804 16.5701 80.3604 16.5701Z" fill="white" />
                                    <path d="M72.4903 24.4399C71.5703 24.4399 70.8203 25.1899 70.8203 26.1099C70.8203 27.0299 71.5703 27.7799 72.4903 27.7799C73.4103 27.7799 74.1603 27.0299 74.1603 26.1099C74.1603 25.1899 73.4103 24.4399 72.4903 24.4399Z" fill="white" />
                                    <path d="M80.3604 33.1499C79.4404 33.1499 78.6904 33.8999 78.6904 34.8199C78.6904 35.7399 79.4404 36.4899 80.3604 36.4899C81.2804 36.4899 82.0304 35.7399 82.0304 34.8199C82.0304 33.8999 81.2804 33.1499 80.3604 33.1499Z" fill="white" />
                                    <path d="M72.4903 41.02C71.5703 41.02 70.8203 41.77 70.8203 42.69C70.8203 43.61 71.5703 44.36 72.4903 44.36C73.4103 44.36 74.1603 43.61 74.1603 42.69C74.1603 41.77 73.4103 41.02 72.4903 41.02Z" fill="white" />
                                    <path d="M80.3604 49.72C79.4404 49.72 78.6904 50.47 78.6904 51.39C78.6904 52.31 79.4404 53.06 80.3604 53.06C81.2804 53.06 82.0304 52.31 82.0304 51.39C82.0304 50.47 81.2804 49.72 80.3604 49.72Z" fill="white" />
                                    <path d="M72.4903 57.5901C71.5703 57.5901 70.8203 58.3401 70.8203 59.2601C70.8203 60.1801 71.5703 60.9301 72.4903 60.9301C73.4103 60.9301 74.1603 60.1801 74.1603 59.2601C74.1603 58.3401 73.4103 57.5901 72.4903 57.5901Z" fill="white" />
                                    <path d="M80.3604 66.3C79.4404 66.3 78.6904 67.05 78.6904 67.97C78.6904 68.89 79.4404 69.64 80.3604 69.64C81.2804 69.64 82.0304 68.89 82.0304 67.97C82.0304 67.05 81.2804 66.3 80.3604 66.3Z" fill="white" />
                                    <path d="M96.0997 0C95.1797 0 94.4297 0.75 94.4297 1.67C94.4297 2.59 95.1797 3.34 96.0997 3.34C97.0197 3.34 97.7697 2.59 97.7697 1.67C97.7697 0.75 97.0197 0 96.0997 0Z" fill="white" />
                                    <path d="M88.2296 7.87012C87.4196 7.87012 86.7496 8.44012 86.5896 9.21012C86.5696 9.32012 86.5596 9.43012 86.5596 9.55012C86.5596 9.67012 86.5696 9.78012 86.5896 9.89012C86.7496 10.6501 87.4196 11.2301 88.2296 11.2301C89.1496 11.2301 89.8996 10.4801 89.8996 9.56012C89.8996 8.64012 89.1496 7.89012 88.2296 7.89012V7.87012Z" fill="white" />
                                    <path d="M96.0997 16.5701C95.1797 16.5701 94.4297 17.3201 94.4297 18.2401C94.4297 19.1601 95.1797 19.9101 96.0997 19.9101C97.0197 19.9101 97.7697 19.1601 97.7697 18.2401C97.7697 17.3201 97.0197 16.5701 96.0997 16.5701Z" fill="white" />
                                    <path d="M88.2296 24.4399C87.4196 24.4399 86.7496 25.0099 86.5896 25.7799C86.5696 25.8899 86.5596 25.9999 86.5596 26.1199C86.5596 26.2399 86.5696 26.3499 86.5896 26.4599C86.7496 27.2199 87.4196 27.7999 88.2296 27.7999C89.1496 27.7999 89.8996 27.0499 89.8996 26.1299C89.8996 25.2099 89.1496 24.4599 88.2296 24.4599V24.4399Z" fill="white" />
                                    <path d="M96.0997 33.1499C95.1797 33.1499 94.4297 33.8999 94.4297 34.8199C94.4297 35.7399 95.1797 36.4899 96.0997 36.4899C97.0197 36.4899 97.7697 35.7399 97.7697 34.8199C97.7697 33.8999 97.0197 33.1499 96.0997 33.1499Z" fill="white" />
                                    <path d="M88.2296 41.02C87.4196 41.02 86.7496 41.59 86.5896 42.36C86.5696 42.47 86.5596 42.58 86.5596 42.7C86.5596 42.82 86.5696 42.93 86.5896 43.04C86.7496 43.8 87.4196 44.38 88.2296 44.38C89.1496 44.38 89.8996 43.63 89.8996 42.71C89.8996 41.79 89.1496 41.04 88.2296 41.04V41.02Z" fill="white" />
                                    <path d="M96.0997 49.72C95.1797 49.72 94.4297 50.47 94.4297 51.39C94.4297 52.31 95.1797 53.06 96.0997 53.06C97.0197 53.06 97.7697 52.31 97.7697 51.39C97.7697 50.47 97.0197 49.72 96.0997 49.72Z" fill="white" />
                                    <path d="M88.2296 57.5901C87.4196 57.5901 86.7496 58.1601 86.5896 58.9301C86.5696 59.0401 86.5596 59.1501 86.5596 59.2701C86.5596 59.3901 86.5696 59.5001 86.5896 59.6101C86.7496 60.3701 87.4196 60.9501 88.2296 60.9501C89.1496 60.9501 89.8996 60.2001 89.8996 59.2801C89.8996 58.3601 89.1496 57.6101 88.2296 57.6101V57.5901Z" fill="white" />
                                    <path d="M96.0997 66.3C95.1797 66.3 94.4297 67.05 94.4297 67.97C94.4297 68.89 95.1797 69.64 96.0997 69.64C97.0197 69.64 97.7697 68.89 97.7697 67.97C97.7697 67.05 97.0197 66.3 96.0997 66.3Z" fill="white" />
                                    <path d="M111.83 0C110.91 0 110.16 0.75 110.16 1.67C110.16 2.59 110.91 3.34 111.83 3.34C112.75 3.34 113.5 2.59 113.5 1.67C113.5 0.75 112.75 0 111.83 0Z" fill="white" />
                                    <path d="M103.97 7.87012C103.05 7.87012 102.3 8.62012 102.3 9.54012C102.3 10.4601 103.05 11.2101 103.97 11.2101C104.89 11.2101 105.64 10.4601 105.64 9.54012C105.64 8.62012 104.89 7.87012 103.97 7.87012Z" fill="white" />
                                    <path d="M111.83 16.5701C110.91 16.5701 110.16 17.3201 110.16 18.2401C110.16 19.1601 110.91 19.9101 111.83 19.9101C112.75 19.9101 113.5 19.1601 113.5 18.2401C113.5 17.3201 112.75 16.5701 111.83 16.5701Z" fill="white" />
                                    <path d="M103.97 24.4399C103.05 24.4399 102.3 25.1899 102.3 26.1099C102.3 27.0299 103.05 27.7799 103.97 27.7799C104.89 27.7799 105.64 27.0299 105.64 26.1099C105.64 25.1899 104.89 24.4399 103.97 24.4399Z" fill="white" />
                                    <path d="M111.83 33.1499C110.91 33.1499 110.16 33.8999 110.16 34.8199C110.16 35.7399 110.91 36.4899 111.83 36.4899C112.75 36.4899 113.5 35.7399 113.5 34.8199C113.5 33.8999 112.75 33.1499 111.83 33.1499Z" fill="white" />
                                    <path d="M103.97 41.02C103.05 41.02 102.3 41.77 102.3 42.69C102.3 43.61 103.05 44.36 103.97 44.36C104.89 44.36 105.64 43.61 105.64 42.69C105.64 41.77 104.89 41.02 103.97 41.02Z" fill="white" />
                                    <path d="M111.83 49.72C110.91 49.72 110.16 50.47 110.16 51.39C110.16 52.31 110.91 53.06 111.83 53.06C112.75 53.06 113.5 52.31 113.5 51.39C113.5 50.47 112.75 49.72 111.83 49.72Z" fill="white" />
                                    <path d="M103.97 57.5901C103.05 57.5901 102.3 58.3401 102.3 59.2601C102.3 60.1801 103.05 60.9301 103.97 60.9301C104.89 60.9301 105.64 60.1801 105.64 59.2601C105.64 58.3401 104.89 57.5901 103.97 57.5901Z" fill="white" />
                                    <path d="M111.83 66.3C110.91 66.3 110.16 67.05 110.16 67.97C110.16 68.89 110.91 69.64 111.83 69.64C112.75 69.64 113.5 68.89 113.5 67.97C113.5 67.05 112.75 66.3 111.83 66.3Z" fill="white" />
                                    <path d="M127.57 0C126.65 0 125.9 0.75 125.9 1.67C125.9 2.59 126.65 3.34 127.57 3.34C128.49 3.34 129.24 2.59 129.24 1.67C129.24 0.75 128.49 0 127.57 0Z" fill="white" />
                                    <path d="M119.7 7.87012C118.78 7.87012 118.03 8.62012 118.03 9.54012C118.03 10.4601 118.78 11.2101 119.7 11.2101C120.62 11.2101 121.37 10.4601 121.37 9.54012C121.37 8.62012 120.62 7.87012 119.7 7.87012Z" fill="white" />
                                    <path d="M127.57 16.5701C126.65 16.5701 125.9 17.3201 125.9 18.2401C125.9 19.1601 126.65 19.9101 127.57 19.9101C128.49 19.9101 129.24 19.1601 129.24 18.2401C129.24 17.3201 128.49 16.5701 127.57 16.5701Z" fill="white" />
                                    <path d="M119.7 24.4399C118.78 24.4399 118.03 25.1899 118.03 26.1099C118.03 27.0299 118.78 27.7799 119.7 27.7799C120.62 27.7799 121.37 27.0299 121.37 26.1099C121.37 25.1899 120.62 24.4399 119.7 24.4399Z" fill="white" />
                                    <path d="M127.57 33.1499C126.65 33.1499 125.9 33.8999 125.9 34.8199C125.9 35.7399 126.65 36.4899 127.57 36.4899C128.49 36.4899 129.24 35.7399 129.24 34.8199C129.24 33.8999 128.49 33.1499 127.57 33.1499Z" fill="white" />
                                    <path d="M119.7 41.02C118.78 41.02 118.03 41.77 118.03 42.69C118.03 43.61 118.78 44.36 119.7 44.36C120.62 44.36 121.37 43.61 121.37 42.69C121.37 41.77 120.62 41.02 119.7 41.02Z" fill="white" />
                                    <path d="M127.57 49.72C126.65 49.72 125.9 50.47 125.9 51.39C125.9 52.31 126.65 53.06 127.57 53.06C128.49 53.06 129.24 52.31 129.24 51.39C129.24 50.47 128.49 49.72 127.57 49.72Z" fill="white" />
                                    <path d="M119.7 57.5901C118.78 57.5901 118.03 58.3401 118.03 59.2601C118.03 60.1801 118.78 60.9301 119.7 60.9301C120.62 60.9301 121.37 60.1801 121.37 59.2601C121.37 58.3401 120.62 57.5901 119.7 57.5901Z" fill="white" />
                                    <path d="M127.57 66.3C126.65 66.3 125.9 67.05 125.9 67.97C125.9 68.89 126.65 69.64 127.57 69.64C128.49 69.64 129.24 68.89 129.24 67.97C129.24 67.05 128.49 66.3 127.57 66.3Z" fill="white" />
                                    <path d="M143.31 0C142.39 0 141.64 0.75 141.64 1.67C141.64 2.59 142.39 3.34 143.31 3.34C144.23 3.34 144.98 2.59 144.98 1.67C144.98 0.75 144.23 0 143.31 0Z" fill="white" />
                                    <path d="M135.44 7.87012C134.52 7.87012 133.77 8.62012 133.77 9.54012C133.77 10.4601 134.52 11.2101 135.44 11.2101C136.36 11.2101 137.11 10.4601 137.11 9.54012C137.11 8.62012 136.36 7.87012 135.44 7.87012Z" fill="white" />
                                    <path d="M143.31 16.5701C142.39 16.5701 141.64 17.3201 141.64 18.2401C141.64 19.1601 142.39 19.9101 143.31 19.9101C144.23 19.9101 144.98 19.1601 144.98 18.2401C144.98 17.3201 144.23 16.5701 143.31 16.5701Z" fill="white" />
                                    <path d="M135.44 24.4399C134.52 24.4399 133.77 25.1899 133.77 26.1099C133.77 27.0299 134.52 27.7799 135.44 27.7799C136.36 27.7799 137.11 27.0299 137.11 26.1099C137.11 25.1899 136.36 24.4399 135.44 24.4399Z" fill="white" />
                                    <path d="M143.31 33.1499C142.39 33.1499 141.64 33.8999 141.64 34.8199C141.64 35.7399 142.39 36.4899 143.31 36.4899C144.23 36.4899 144.98 35.7399 144.98 34.8199C144.98 33.8999 144.23 33.1499 143.31 33.1499Z" fill="white" />
                                    <path d="M135.44 41.02C134.52 41.02 133.77 41.77 133.77 42.69C133.77 43.61 134.52 44.36 135.44 44.36C136.36 44.36 137.11 43.61 137.11 42.69C137.11 41.77 136.36 41.02 135.44 41.02Z" fill="white" />
                                    <path d="M143.31 49.72C142.39 49.72 141.64 50.47 141.64 51.39C141.64 52.31 142.39 53.06 143.31 53.06C144.23 53.06 144.98 52.31 144.98 51.39C144.98 50.47 144.23 49.72 143.31 49.72Z" fill="white" />
                                    <path d="M135.44 57.5901C134.52 57.5901 133.77 58.3401 133.77 59.2601C133.77 60.1801 134.52 60.9301 135.44 60.9301C136.36 60.9301 137.11 60.1801 137.11 59.2601C137.11 58.3401 136.36 57.5901 135.44 57.5901Z" fill="white" />
                                    <path d="M143.31 66.3C142.39 66.3 141.64 67.05 141.64 67.97C141.64 68.89 142.39 69.64 143.31 69.64C144.23 69.64 144.98 68.89 144.98 67.97C144.98 67.05 144.23 66.3 143.31 66.3Z" fill="white" />
                                    <path d="M159.05 0C158.13 0 157.38 0.75 157.38 1.67C157.38 2.59 158.13 3.34 159.05 3.34C159.97 3.34 160.72 2.59 160.72 1.67C160.72 0.75 159.97 0 159.05 0Z" fill="white" />
                                    <path d="M151.18 7.87012C150.26 7.87012 149.51 8.62012 149.51 9.54012C149.51 10.4601 150.26 11.2101 151.18 11.2101C152.1 11.2101 152.85 10.4601 152.85 9.54012C152.85 8.62012 152.1 7.87012 151.18 7.87012Z" fill="white" />
                                    <path d="M159.05 16.5701C158.13 16.5701 157.38 17.3201 157.38 18.2401C157.38 19.1601 158.13 19.9101 159.05 19.9101C159.97 19.9101 160.72 19.1601 160.72 18.2401C160.72 17.3201 159.97 16.5701 159.05 16.5701Z" fill="white" />
                                    <path d="M151.18 24.4399C150.26 24.4399 149.51 25.1899 149.51 26.1099C149.51 27.0299 150.26 27.7799 151.18 27.7799C152.1 27.7799 152.85 27.0299 152.85 26.1099C152.85 25.1899 152.1 24.4399 151.18 24.4399Z" fill="white" />
                                    <path d="M159.05 33.1499C158.13 33.1499 157.38 33.8999 157.38 34.8199C157.38 35.7399 158.13 36.4899 159.05 36.4899C159.97 36.4899 160.72 35.7399 160.72 34.8199C160.72 33.8999 159.97 33.1499 159.05 33.1499Z" fill="white" />
                                    <path d="M151.18 41.02C150.26 41.02 149.51 41.77 149.51 42.69C149.51 43.61 150.26 44.36 151.18 44.36C152.1 44.36 152.85 43.61 152.85 42.69C152.85 41.77 152.1 41.02 151.18 41.02Z" fill="white" />
                                    <path d="M159.05 49.72C158.13 49.72 157.38 50.47 157.38 51.39C157.38 52.31 158.13 53.06 159.05 53.06C159.97 53.06 160.72 52.31 160.72 51.39C160.72 50.47 159.97 49.72 159.05 49.72Z" fill="white" />
                                    <path d="M151.18 57.5901C150.26 57.5901 149.51 58.3401 149.51 59.2601C149.51 60.1801 150.26 60.9301 151.18 60.9301C152.1 60.9301 152.85 60.1801 152.85 59.2601C152.85 58.3401 152.1 57.5901 151.18 57.5901Z" fill="white" />
                                    <path d="M159.05 66.3C158.13 66.3 157.38 67.05 157.38 67.97C157.38 68.89 158.13 69.64 159.05 69.64C159.97 69.64 160.72 68.89 160.72 67.97C160.72 67.05 159.97 66.3 159.05 66.3Z" fill="white" />
                                    <path d="M174.78 3.34C175.703 3.34 176.45 2.59232 176.45 1.67C176.45 0.747684 175.703 0 174.78 0C173.858 0 173.11 0.747684 173.11 1.67C173.11 2.59232 173.858 3.34 174.78 3.34Z" fill="white" />
                                    <path d="M166.91 7.87012C165.99 7.87012 165.24 8.62012 165.24 9.54012C165.24 10.4601 165.99 11.2101 166.91 11.2101C167.83 11.2101 168.58 10.4601 168.58 9.54012C168.58 8.62012 167.83 7.87012 166.91 7.87012Z" fill="white" />
                                    <path d="M174.78 16.5701C173.86 16.5701 173.11 17.3201 173.11 18.2401C173.11 19.1601 173.86 19.9101 174.78 19.9101C175.7 19.9101 176.45 19.1601 176.45 18.2401C176.45 17.3201 175.7 16.5701 174.78 16.5701Z" fill="white" />
                                    <path d="M166.91 24.4399C165.99 24.4399 165.24 25.1899 165.24 26.1099C165.24 27.0299 165.99 27.7799 166.91 27.7799C167.83 27.7799 168.58 27.0299 168.58 26.1099C168.58 25.1899 167.83 24.4399 166.91 24.4399Z" fill="white" />
                                    <path d="M174.78 33.1499C173.86 33.1499 173.11 33.8999 173.11 34.8199C173.11 35.7399 173.86 36.4899 174.78 36.4899C175.7 36.4899 176.45 35.7399 176.45 34.8199C176.45 33.8999 175.7 33.1499 174.78 33.1499Z" fill="white" />
                                    <path d="M166.91 41.02C165.99 41.02 165.24 41.77 165.24 42.69C165.24 43.61 165.99 44.36 166.91 44.36C167.83 44.36 168.58 43.61 168.58 42.69C168.58 41.77 167.83 41.02 166.91 41.02Z" fill="white" />
                                    <path d="M174.78 49.72C173.86 49.72 173.11 50.47 173.11 51.39C173.11 52.31 173.86 53.06 174.78 53.06C175.7 53.06 176.45 52.31 176.45 51.39C176.45 50.47 175.7 49.72 174.78 49.72Z" fill="white" />
                                    <path d="M166.91 57.5901C165.99 57.5901 165.24 58.3401 165.24 59.2601C165.24 60.1801 165.99 60.9301 166.91 60.9301C167.83 60.9301 168.58 60.1801 168.58 59.2601C168.58 58.3401 167.83 57.5901 166.91 57.5901Z" fill="white" />
                                    <path d="M174.78 69.64C175.703 69.64 176.45 68.8924 176.45 67.97C176.45 67.0477 175.703 66.3 174.78 66.3C173.858 66.3 173.11 67.0477 173.11 67.97C173.11 68.8924 173.858 69.64 174.78 69.64Z" fill="white" />
                                    <path d="M190.52 0C189.6 0 188.85 0.75 188.85 1.67C188.85 2.59 189.6 3.34 190.52 3.34C191.44 3.34 192.19 2.59 192.19 1.67C192.19 0.75 191.44 0 190.52 0Z" fill="white" />
                                    <path d="M182.65 7.87012C181.73 7.87012 180.98 8.62012 180.98 9.54012C180.98 10.4601 181.73 11.2101 182.65 11.2101C183.57 11.2101 184.32 10.4601 184.32 9.54012C184.32 8.62012 183.57 7.87012 182.65 7.87012Z" fill="white" />
                                    <path d="M190.52 16.5701C189.6 16.5701 188.85 17.3201 188.85 18.2401C188.85 19.1601 189.6 19.9101 190.52 19.9101C191.44 19.9101 192.19 19.1601 192.19 18.2401C192.19 17.3201 191.44 16.5701 190.52 16.5701Z" fill="white" />
                                    <path d="M182.65 24.4399C181.73 24.4399 180.98 25.1899 180.98 26.1099C180.98 27.0299 181.73 27.7799 182.65 27.7799C183.57 27.7799 184.32 27.0299 184.32 26.1099C184.32 25.1899 183.57 24.4399 182.65 24.4399Z" fill="white" />
                                    <path d="M190.52 33.1499C189.6 33.1499 188.85 33.8999 188.85 34.8199C188.85 35.7399 189.6 36.4899 190.52 36.4899C191.44 36.4899 192.19 35.7399 192.19 34.8199C192.19 33.8999 191.44 33.1499 190.52 33.1499Z" fill="white" />
                                    <path d="M182.65 41.02C181.73 41.02 180.98 41.77 180.98 42.69C180.98 43.61 181.73 44.36 182.65 44.36C183.57 44.36 184.32 43.61 184.32 42.69C184.32 41.77 183.57 41.02 182.65 41.02Z" fill="white" />
                                    <path d="M190.52 49.72C189.6 49.72 188.85 50.47 188.85 51.39C188.85 52.31 189.6 53.06 190.52 53.06C191.44 53.06 192.19 52.31 192.19 51.39C192.19 50.47 191.44 49.72 190.52 49.72Z" fill="white" />
                                    <path d="M182.65 57.5901C181.73 57.5901 180.98 58.3401 180.98 59.2601C180.98 60.1801 181.73 60.9301 182.65 60.9301C183.57 60.9301 184.32 60.1801 184.32 59.2601C184.32 58.3401 183.57 57.5901 182.65 57.5901Z" fill="white" />
                                    <path d="M190.52 66.3C189.6 66.3 188.85 67.05 188.85 67.97C188.85 68.89 189.6 69.64 190.52 69.64C191.44 69.64 192.19 68.89 192.19 67.97C192.19 67.05 191.44 66.3 190.52 66.3Z" fill="white" />
                                </svg>
                                <h4 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("SOLUTIONS")}</h4>
                            </div>
                            <span>AUS</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="img-slide-three">
                            <h2 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("DRIVEN-BY")}</h2>
                            <div className="flex-aswad-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="193" height="70" viewBox="0 0 193 70" fill="none">
                                    <path d="M1.67 3.34C2.59232 3.34 3.34 2.59232 3.34 1.67C3.34 0.747684 2.59232 0 1.67 0C0.747684 0 0 0.747684 0 1.67C0 2.59232 0.747684 3.34 1.67 3.34Z" fill="white" />
                                    <path d="M1.67 16.5701C0.75 16.5701 0 17.3201 0 18.2401C0 19.1601 0.75 19.9101 1.67 19.9101C2.59 19.9101 3.34 19.1601 3.34 18.2401C3.34 17.3201 2.59 16.5701 1.67 16.5701Z" fill="white" />
                                    <path d="M1.67 33.1499C0.75 33.1499 0 33.8999 0 34.8199C0 35.7399 0.75 36.4899 1.67 36.4899C2.59 36.4899 3.34 35.7399 3.34 34.8199C3.34 33.8999 2.59 33.1499 1.67 33.1499Z" fill="white" />
                                    <path d="M1.67 49.72C0.75 49.72 0 50.47 0 51.39C0 52.31 0.75 53.06 1.67 53.06C2.59 53.06 3.34 52.31 3.34 51.39C3.34 50.47 2.59 49.72 1.67 49.72Z" fill="white" />
                                    <path d="M1.67 69.64C2.59232 69.64 3.34 68.8924 3.34 67.97C3.34 67.0477 2.59232 66.3 1.67 66.3C0.747684 66.3 0 67.0477 0 67.97C0 68.8924 0.747684 69.64 1.67 69.64Z" fill="white" />
                                    <path d="M17.4102 3.34C18.3326 3.34 19.0802 2.59232 19.0802 1.67C19.0802 0.747684 18.3326 0 17.4102 0C16.4879 0 15.7402 0.747684 15.7402 1.67C15.7402 2.59232 16.4879 3.34 17.4102 3.34Z" fill="white" />
                                    <path d="M9.54012 7.87012C8.62012 7.87012 7.87012 8.62012 7.87012 9.54012C7.87012 10.4601 8.62012 11.2101 9.54012 11.2101C10.4601 11.2101 11.2101 10.4601 11.2101 9.54012C11.2101 8.62012 10.4601 7.87012 9.54012 7.87012Z" fill="white" />
                                    <path d="M17.4102 16.5701C16.4902 16.5701 15.7402 17.3201 15.7402 18.2401C15.7402 19.1601 16.4902 19.9101 17.4102 19.9101C18.3302 19.9101 19.0802 19.1601 19.0802 18.2401C19.0802 17.3201 18.3302 16.5701 17.4102 16.5701Z" fill="white" />
                                    <path d="M9.54012 24.4399C8.62012 24.4399 7.87012 25.1899 7.87012 26.1099C7.87012 27.0299 8.62012 27.7799 9.54012 27.7799C10.4601 27.7799 11.2101 27.0299 11.2101 26.1099C11.2101 25.1899 10.4601 24.4399 9.54012 24.4399Z" fill="white" />
                                    <path d="M17.4102 33.1499C16.4902 33.1499 15.7402 33.8999 15.7402 34.8199C15.7402 35.7399 16.4902 36.4899 17.4102 36.4899C18.3302 36.4899 19.0802 35.7399 19.0802 34.8199C19.0802 33.8999 18.3302 33.1499 17.4102 33.1499Z" fill="white" />
                                    <path d="M9.54012 41.02C8.62012 41.02 7.87012 41.77 7.87012 42.69C7.87012 43.61 8.62012 44.36 9.54012 44.36C10.4601 44.36 11.2101 43.61 11.2101 42.69C11.2101 41.77 10.4601 41.02 9.54012 41.02Z" fill="white" />
                                    <path d="M17.4102 49.72C16.4902 49.72 15.7402 50.47 15.7402 51.39C15.7402 52.31 16.4902 53.06 17.4102 53.06C18.3302 53.06 19.0802 52.31 19.0802 51.39C19.0802 50.47 18.3302 49.72 17.4102 49.72Z" fill="white" />
                                    <path d="M9.54012 57.5901C8.62012 57.5901 7.87012 58.3401 7.87012 59.2601C7.87012 60.1801 8.62012 60.9301 9.54012 60.9301C10.4601 60.9301 11.2101 60.1801 11.2101 59.2601C11.2101 58.3401 10.4601 57.5901 9.54012 57.5901Z" fill="white" />
                                    <path d="M17.4102 69.64C18.3326 69.64 19.0802 68.8924 19.0802 67.97C19.0802 67.0477 18.3326 66.3 17.4102 66.3C16.4879 66.3 15.7402 67.0477 15.7402 67.97C15.7402 68.8924 16.4879 69.64 17.4102 69.64Z" fill="white" />
                                    <path d="M33.1505 3.34C34.0728 3.34 34.8205 2.59232 34.8205 1.67C34.8205 0.747684 34.0728 0 33.1505 0C32.2282 0 31.4805 0.747684 31.4805 1.67C31.4805 2.59232 32.2282 3.34 33.1505 3.34Z" fill="white" />
                                    <path d="M25.2804 7.87012C24.3604 7.87012 23.6104 8.62012 23.6104 9.54012C23.6104 10.4601 24.3604 11.2101 25.2804 11.2101C26.2004 11.2101 26.9504 10.4601 26.9504 9.54012C26.9504 8.62012 26.2004 7.87012 25.2804 7.87012Z" fill="white" />
                                    <path d="M33.1505 16.5701C32.2305 16.5701 31.4805 17.3201 31.4805 18.2401C31.4805 19.1601 32.2305 19.9101 33.1505 19.9101C34.0705 19.9101 34.8205 19.1601 34.8205 18.2401C34.8205 17.3201 34.0705 16.5701 33.1505 16.5701Z" fill="white" />
                                    <path d="M25.2804 24.4399C24.3604 24.4399 23.6104 25.1899 23.6104 26.1099C23.6104 27.0299 24.3604 27.7799 25.2804 27.7799C26.2004 27.7799 26.9504 27.0299 26.9504 26.1099C26.9504 25.1899 26.2004 24.4399 25.2804 24.4399Z" fill="white" />
                                    <path d="M33.1505 33.1499C32.2305 33.1499 31.4805 33.8999 31.4805 34.8199C31.4805 35.7399 32.2305 36.4899 33.1505 36.4899C34.0705 36.4899 34.8205 35.7399 34.8205 34.8199C34.8205 33.8999 34.0705 33.1499 33.1505 33.1499Z" fill="white" />
                                    <path d="M25.2804 41.02C24.3604 41.02 23.6104 41.77 23.6104 42.69C23.6104 43.61 24.3604 44.36 25.2804 44.36C26.2004 44.36 26.9504 43.61 26.9504 42.69C26.9504 41.77 26.2004 41.02 25.2804 41.02Z" fill="white" />
                                    <path d="M33.1505 49.72C32.2305 49.72 31.4805 50.47 31.4805 51.39C31.4805 52.31 32.2305 53.06 33.1505 53.06C34.0705 53.06 34.8205 52.31 34.8205 51.39C34.8205 50.47 34.0705 49.72 33.1505 49.72Z" fill="white" />
                                    <path d="M25.2804 57.5901C24.3604 57.5901 23.6104 58.3401 23.6104 59.2601C23.6104 60.1801 24.3604 60.9301 25.2804 60.9301C26.2004 60.9301 26.9504 60.1801 26.9504 59.2601C26.9504 58.3401 26.2004 57.5901 25.2804 57.5901Z" fill="white" />
                                    <path d="M33.1505 69.64C34.0728 69.64 34.8205 68.8924 34.8205 67.97C34.8205 67.0477 34.0728 66.3 33.1505 66.3C32.2282 66.3 31.4805 67.0477 31.4805 67.97C31.4805 68.8924 32.2282 69.64 33.1505 69.64Z" fill="white" />
                                    <path d="M48.8897 0C47.9697 0 47.2197 0.75 47.2197 1.67C47.2197 2.59 47.9697 3.34 48.8897 3.34C49.8097 3.34 50.5597 2.59 50.5597 1.67C50.5597 0.75 49.8097 0 48.8897 0Z" fill="white" />
                                    <path d="M41.0196 7.87012C40.0996 7.87012 39.3496 8.62012 39.3496 9.54012C39.3496 10.4601 40.0996 11.2101 41.0196 11.2101C41.9396 11.2101 42.6896 10.4601 42.6896 9.54012C42.6896 8.62012 41.9396 7.87012 41.0196 7.87012Z" fill="white" />
                                    <path d="M48.8897 16.5701C47.9697 16.5701 47.2197 17.3201 47.2197 18.2401C47.2197 19.1601 47.9697 19.9101 48.8897 19.9101C49.8097 19.9101 50.5597 19.1601 50.5597 18.2401C50.5597 17.3201 49.8097 16.5701 48.8897 16.5701Z" fill="white" />
                                    <path d="M41.0196 24.4399C40.0996 24.4399 39.3496 25.1899 39.3496 26.1099C39.3496 27.0299 40.0996 27.7799 41.0196 27.7799C41.9396 27.7799 42.6896 27.0299 42.6896 26.1099C42.6896 25.1899 41.9396 24.4399 41.0196 24.4399Z" fill="white" />
                                    <path d="M48.8897 33.1499C47.9697 33.1499 47.2197 33.8999 47.2197 34.8199C47.2197 35.7399 47.9697 36.4899 48.8897 36.4899C49.8097 36.4899 50.5597 35.7399 50.5597 34.8199C50.5597 33.8999 49.8097 33.1499 48.8897 33.1499Z" fill="white" />
                                    <path d="M41.0196 41.02C40.0996 41.02 39.3496 41.77 39.3496 42.69C39.3496 43.61 40.0996 44.36 41.0196 44.36C41.9396 44.36 42.6896 43.61 42.6896 42.69C42.6896 41.77 41.9396 41.02 41.0196 41.02Z" fill="white" />
                                    <path d="M48.8897 49.72C47.9697 49.72 47.2197 50.47 47.2197 51.39C47.2197 52.31 47.9697 53.06 48.8897 53.06C49.8097 53.06 50.5597 52.31 50.5597 51.39C50.5597 50.47 49.8097 49.72 48.8897 49.72Z" fill="white" />
                                    <path d="M41.0196 57.5901C40.0996 57.5901 39.3496 58.3401 39.3496 59.2601C39.3496 60.1801 40.0996 60.9301 41.0196 60.9301C41.9396 60.9301 42.6896 60.1801 42.6896 59.2601C42.6896 58.3401 41.9396 57.5901 41.0196 57.5901Z" fill="white" />
                                    <path d="M48.8897 66.3C47.9697 66.3 47.2197 67.05 47.2197 67.97C47.2197 68.89 47.9697 69.64 48.8897 69.64C49.8097 69.64 50.5597 68.89 50.5597 67.97C50.5597 67.05 49.8097 66.3 48.8897 66.3Z" fill="white" />
                                    <path d="M64.6202 0C63.7002 0 62.9502 0.75 62.9502 1.67C62.9502 2.59 63.7002 3.34 64.6202 3.34C65.5402 3.34 66.2902 2.59 66.2902 1.67C66.2902 0.75 65.5402 0 64.6202 0Z" fill="white" />
                                    <path d="M56.7501 7.87012C55.8301 7.87012 55.0801 8.62012 55.0801 9.54012C55.0801 10.4601 55.8301 11.2101 56.7501 11.2101C57.6701 11.2101 58.4201 10.4601 58.4201 9.54012C58.4201 8.62012 57.6701 7.87012 56.7501 7.87012Z" fill="white" />
                                    <path d="M64.6202 16.5701C63.7002 16.5701 62.9502 17.3201 62.9502 18.2401C62.9502 19.1601 63.7002 19.9101 64.6202 19.9101C65.5402 19.9101 66.2902 19.1601 66.2902 18.2401C66.2902 17.3201 65.5402 16.5701 64.6202 16.5701Z" fill="white" />
                                    <path d="M56.7501 24.4399C55.8301 24.4399 55.0801 25.1899 55.0801 26.1099C55.0801 27.0299 55.8301 27.7799 56.7501 27.7799C57.6701 27.7799 58.4201 27.0299 58.4201 26.1099C58.4201 25.1899 57.6701 24.4399 56.7501 24.4399Z" fill="white" />
                                    <path d="M64.6202 33.1499C63.7002 33.1499 62.9502 33.8999 62.9502 34.8199C62.9502 35.7399 63.7002 36.4899 64.6202 36.4899C65.5402 36.4899 66.2902 35.7399 66.2902 34.8199C66.2902 33.8999 65.5402 33.1499 64.6202 33.1499Z" fill="white" />
                                    <path d="M56.7501 41.02C55.8301 41.02 55.0801 41.77 55.0801 42.69C55.0801 43.61 55.8301 44.36 56.7501 44.36C57.6701 44.36 58.4201 43.61 58.4201 42.69C58.4201 41.77 57.6701 41.02 56.7501 41.02Z" fill="white" />
                                    <path d="M64.6202 49.72C63.7002 49.72 62.9502 50.47 62.9502 51.39C62.9502 52.31 63.7002 53.06 64.6202 53.06C65.5402 53.06 66.2902 52.31 66.2902 51.39C66.2902 50.47 65.5402 49.72 64.6202 49.72Z" fill="white" />
                                    <path d="M56.7501 57.5901C55.8301 57.5901 55.0801 58.3401 55.0801 59.2601C55.0801 60.1801 55.8301 60.9301 56.7501 60.9301C57.6701 60.9301 58.4201 60.1801 58.4201 59.2601C58.4201 58.3401 57.6701 57.5901 56.7501 57.5901Z" fill="white" />
                                    <path d="M64.6202 66.3C63.7002 66.3 62.9502 67.05 62.9502 67.97C62.9502 68.89 63.7002 69.64 64.6202 69.64C65.5402 69.64 66.2902 68.89 66.2902 67.97C66.2902 67.05 65.5402 66.3 64.6202 66.3Z" fill="white" />
                                    <path d="M80.3604 0C79.4404 0 78.6904 0.75 78.6904 1.67C78.6904 2.59 79.4404 3.34 80.3604 3.34C81.2804 3.34 82.0304 2.59 82.0304 1.67C82.0304 0.75 81.2804 0 80.3604 0Z" fill="white" />
                                    <path d="M72.4903 7.87012C71.5703 7.87012 70.8203 8.62012 70.8203 9.54012C70.8203 10.4601 71.5703 11.2101 72.4903 11.2101C73.4103 11.2101 74.1603 10.4601 74.1603 9.54012C74.1603 8.62012 73.4103 7.87012 72.4903 7.87012Z" fill="white" />
                                    <path d="M80.3604 16.5701C79.4404 16.5701 78.6904 17.3201 78.6904 18.2401C78.6904 19.1601 79.4404 19.9101 80.3604 19.9101C81.2804 19.9101 82.0304 19.1601 82.0304 18.2401C82.0304 17.3201 81.2804 16.5701 80.3604 16.5701Z" fill="white" />
                                    <path d="M72.4903 24.4399C71.5703 24.4399 70.8203 25.1899 70.8203 26.1099C70.8203 27.0299 71.5703 27.7799 72.4903 27.7799C73.4103 27.7799 74.1603 27.0299 74.1603 26.1099C74.1603 25.1899 73.4103 24.4399 72.4903 24.4399Z" fill="white" />
                                    <path d="M80.3604 33.1499C79.4404 33.1499 78.6904 33.8999 78.6904 34.8199C78.6904 35.7399 79.4404 36.4899 80.3604 36.4899C81.2804 36.4899 82.0304 35.7399 82.0304 34.8199C82.0304 33.8999 81.2804 33.1499 80.3604 33.1499Z" fill="white" />
                                    <path d="M72.4903 41.02C71.5703 41.02 70.8203 41.77 70.8203 42.69C70.8203 43.61 71.5703 44.36 72.4903 44.36C73.4103 44.36 74.1603 43.61 74.1603 42.69C74.1603 41.77 73.4103 41.02 72.4903 41.02Z" fill="white" />
                                    <path d="M80.3604 49.72C79.4404 49.72 78.6904 50.47 78.6904 51.39C78.6904 52.31 79.4404 53.06 80.3604 53.06C81.2804 53.06 82.0304 52.31 82.0304 51.39C82.0304 50.47 81.2804 49.72 80.3604 49.72Z" fill="white" />
                                    <path d="M72.4903 57.5901C71.5703 57.5901 70.8203 58.3401 70.8203 59.2601C70.8203 60.1801 71.5703 60.9301 72.4903 60.9301C73.4103 60.9301 74.1603 60.1801 74.1603 59.2601C74.1603 58.3401 73.4103 57.5901 72.4903 57.5901Z" fill="white" />
                                    <path d="M80.3604 66.3C79.4404 66.3 78.6904 67.05 78.6904 67.97C78.6904 68.89 79.4404 69.64 80.3604 69.64C81.2804 69.64 82.0304 68.89 82.0304 67.97C82.0304 67.05 81.2804 66.3 80.3604 66.3Z" fill="white" />
                                    <path d="M96.0997 0C95.1797 0 94.4297 0.75 94.4297 1.67C94.4297 2.59 95.1797 3.34 96.0997 3.34C97.0197 3.34 97.7697 2.59 97.7697 1.67C97.7697 0.75 97.0197 0 96.0997 0Z" fill="white" />
                                    <path d="M88.2296 7.87012C87.4196 7.87012 86.7496 8.44012 86.5896 9.21012C86.5696 9.32012 86.5596 9.43012 86.5596 9.55012C86.5596 9.67012 86.5696 9.78012 86.5896 9.89012C86.7496 10.6501 87.4196 11.2301 88.2296 11.2301C89.1496 11.2301 89.8996 10.4801 89.8996 9.56012C89.8996 8.64012 89.1496 7.89012 88.2296 7.89012V7.87012Z" fill="white" />
                                    <path d="M96.0997 16.5701C95.1797 16.5701 94.4297 17.3201 94.4297 18.2401C94.4297 19.1601 95.1797 19.9101 96.0997 19.9101C97.0197 19.9101 97.7697 19.1601 97.7697 18.2401C97.7697 17.3201 97.0197 16.5701 96.0997 16.5701Z" fill="white" />
                                    <path d="M88.2296 24.4399C87.4196 24.4399 86.7496 25.0099 86.5896 25.7799C86.5696 25.8899 86.5596 25.9999 86.5596 26.1199C86.5596 26.2399 86.5696 26.3499 86.5896 26.4599C86.7496 27.2199 87.4196 27.7999 88.2296 27.7999C89.1496 27.7999 89.8996 27.0499 89.8996 26.1299C89.8996 25.2099 89.1496 24.4599 88.2296 24.4599V24.4399Z" fill="white" />
                                    <path d="M96.0997 33.1499C95.1797 33.1499 94.4297 33.8999 94.4297 34.8199C94.4297 35.7399 95.1797 36.4899 96.0997 36.4899C97.0197 36.4899 97.7697 35.7399 97.7697 34.8199C97.7697 33.8999 97.0197 33.1499 96.0997 33.1499Z" fill="white" />
                                    <path d="M88.2296 41.02C87.4196 41.02 86.7496 41.59 86.5896 42.36C86.5696 42.47 86.5596 42.58 86.5596 42.7C86.5596 42.82 86.5696 42.93 86.5896 43.04C86.7496 43.8 87.4196 44.38 88.2296 44.38C89.1496 44.38 89.8996 43.63 89.8996 42.71C89.8996 41.79 89.1496 41.04 88.2296 41.04V41.02Z" fill="white" />
                                    <path d="M96.0997 49.72C95.1797 49.72 94.4297 50.47 94.4297 51.39C94.4297 52.31 95.1797 53.06 96.0997 53.06C97.0197 53.06 97.7697 52.31 97.7697 51.39C97.7697 50.47 97.0197 49.72 96.0997 49.72Z" fill="white" />
                                    <path d="M88.2296 57.5901C87.4196 57.5901 86.7496 58.1601 86.5896 58.9301C86.5696 59.0401 86.5596 59.1501 86.5596 59.2701C86.5596 59.3901 86.5696 59.5001 86.5896 59.6101C86.7496 60.3701 87.4196 60.9501 88.2296 60.9501C89.1496 60.9501 89.8996 60.2001 89.8996 59.2801C89.8996 58.3601 89.1496 57.6101 88.2296 57.6101V57.5901Z" fill="white" />
                                    <path d="M96.0997 66.3C95.1797 66.3 94.4297 67.05 94.4297 67.97C94.4297 68.89 95.1797 69.64 96.0997 69.64C97.0197 69.64 97.7697 68.89 97.7697 67.97C97.7697 67.05 97.0197 66.3 96.0997 66.3Z" fill="white" />
                                    <path d="M111.83 0C110.91 0 110.16 0.75 110.16 1.67C110.16 2.59 110.91 3.34 111.83 3.34C112.75 3.34 113.5 2.59 113.5 1.67C113.5 0.75 112.75 0 111.83 0Z" fill="white" />
                                    <path d="M103.97 7.87012C103.05 7.87012 102.3 8.62012 102.3 9.54012C102.3 10.4601 103.05 11.2101 103.97 11.2101C104.89 11.2101 105.64 10.4601 105.64 9.54012C105.64 8.62012 104.89 7.87012 103.97 7.87012Z" fill="white" />
                                    <path d="M111.83 16.5701C110.91 16.5701 110.16 17.3201 110.16 18.2401C110.16 19.1601 110.91 19.9101 111.83 19.9101C112.75 19.9101 113.5 19.1601 113.5 18.2401C113.5 17.3201 112.75 16.5701 111.83 16.5701Z" fill="white" />
                                    <path d="M103.97 24.4399C103.05 24.4399 102.3 25.1899 102.3 26.1099C102.3 27.0299 103.05 27.7799 103.97 27.7799C104.89 27.7799 105.64 27.0299 105.64 26.1099C105.64 25.1899 104.89 24.4399 103.97 24.4399Z" fill="white" />
                                    <path d="M111.83 33.1499C110.91 33.1499 110.16 33.8999 110.16 34.8199C110.16 35.7399 110.91 36.4899 111.83 36.4899C112.75 36.4899 113.5 35.7399 113.5 34.8199C113.5 33.8999 112.75 33.1499 111.83 33.1499Z" fill="white" />
                                    <path d="M103.97 41.02C103.05 41.02 102.3 41.77 102.3 42.69C102.3 43.61 103.05 44.36 103.97 44.36C104.89 44.36 105.64 43.61 105.64 42.69C105.64 41.77 104.89 41.02 103.97 41.02Z" fill="white" />
                                    <path d="M111.83 49.72C110.91 49.72 110.16 50.47 110.16 51.39C110.16 52.31 110.91 53.06 111.83 53.06C112.75 53.06 113.5 52.31 113.5 51.39C113.5 50.47 112.75 49.72 111.83 49.72Z" fill="white" />
                                    <path d="M103.97 57.5901C103.05 57.5901 102.3 58.3401 102.3 59.2601C102.3 60.1801 103.05 60.9301 103.97 60.9301C104.89 60.9301 105.64 60.1801 105.64 59.2601C105.64 58.3401 104.89 57.5901 103.97 57.5901Z" fill="white" />
                                    <path d="M111.83 66.3C110.91 66.3 110.16 67.05 110.16 67.97C110.16 68.89 110.91 69.64 111.83 69.64C112.75 69.64 113.5 68.89 113.5 67.97C113.5 67.05 112.75 66.3 111.83 66.3Z" fill="white" />
                                    <path d="M127.57 0C126.65 0 125.9 0.75 125.9 1.67C125.9 2.59 126.65 3.34 127.57 3.34C128.49 3.34 129.24 2.59 129.24 1.67C129.24 0.75 128.49 0 127.57 0Z" fill="white" />
                                    <path d="M119.7 7.87012C118.78 7.87012 118.03 8.62012 118.03 9.54012C118.03 10.4601 118.78 11.2101 119.7 11.2101C120.62 11.2101 121.37 10.4601 121.37 9.54012C121.37 8.62012 120.62 7.87012 119.7 7.87012Z" fill="white" />
                                    <path d="M127.57 16.5701C126.65 16.5701 125.9 17.3201 125.9 18.2401C125.9 19.1601 126.65 19.9101 127.57 19.9101C128.49 19.9101 129.24 19.1601 129.24 18.2401C129.24 17.3201 128.49 16.5701 127.57 16.5701Z" fill="white" />
                                    <path d="M119.7 24.4399C118.78 24.4399 118.03 25.1899 118.03 26.1099C118.03 27.0299 118.78 27.7799 119.7 27.7799C120.62 27.7799 121.37 27.0299 121.37 26.1099C121.37 25.1899 120.62 24.4399 119.7 24.4399Z" fill="white" />
                                    <path d="M127.57 33.1499C126.65 33.1499 125.9 33.8999 125.9 34.8199C125.9 35.7399 126.65 36.4899 127.57 36.4899C128.49 36.4899 129.24 35.7399 129.24 34.8199C129.24 33.8999 128.49 33.1499 127.57 33.1499Z" fill="white" />
                                    <path d="M119.7 41.02C118.78 41.02 118.03 41.77 118.03 42.69C118.03 43.61 118.78 44.36 119.7 44.36C120.62 44.36 121.37 43.61 121.37 42.69C121.37 41.77 120.62 41.02 119.7 41.02Z" fill="white" />
                                    <path d="M127.57 49.72C126.65 49.72 125.9 50.47 125.9 51.39C125.9 52.31 126.65 53.06 127.57 53.06C128.49 53.06 129.24 52.31 129.24 51.39C129.24 50.47 128.49 49.72 127.57 49.72Z" fill="white" />
                                    <path d="M119.7 57.5901C118.78 57.5901 118.03 58.3401 118.03 59.2601C118.03 60.1801 118.78 60.9301 119.7 60.9301C120.62 60.9301 121.37 60.1801 121.37 59.2601C121.37 58.3401 120.62 57.5901 119.7 57.5901Z" fill="white" />
                                    <path d="M127.57 66.3C126.65 66.3 125.9 67.05 125.9 67.97C125.9 68.89 126.65 69.64 127.57 69.64C128.49 69.64 129.24 68.89 129.24 67.97C129.24 67.05 128.49 66.3 127.57 66.3Z" fill="white" />
                                    <path d="M143.31 0C142.39 0 141.64 0.75 141.64 1.67C141.64 2.59 142.39 3.34 143.31 3.34C144.23 3.34 144.98 2.59 144.98 1.67C144.98 0.75 144.23 0 143.31 0Z" fill="white" />
                                    <path d="M135.44 7.87012C134.52 7.87012 133.77 8.62012 133.77 9.54012C133.77 10.4601 134.52 11.2101 135.44 11.2101C136.36 11.2101 137.11 10.4601 137.11 9.54012C137.11 8.62012 136.36 7.87012 135.44 7.87012Z" fill="white" />
                                    <path d="M143.31 16.5701C142.39 16.5701 141.64 17.3201 141.64 18.2401C141.64 19.1601 142.39 19.9101 143.31 19.9101C144.23 19.9101 144.98 19.1601 144.98 18.2401C144.98 17.3201 144.23 16.5701 143.31 16.5701Z" fill="white" />
                                    <path d="M135.44 24.4399C134.52 24.4399 133.77 25.1899 133.77 26.1099C133.77 27.0299 134.52 27.7799 135.44 27.7799C136.36 27.7799 137.11 27.0299 137.11 26.1099C137.11 25.1899 136.36 24.4399 135.44 24.4399Z" fill="white" />
                                    <path d="M143.31 33.1499C142.39 33.1499 141.64 33.8999 141.64 34.8199C141.64 35.7399 142.39 36.4899 143.31 36.4899C144.23 36.4899 144.98 35.7399 144.98 34.8199C144.98 33.8999 144.23 33.1499 143.31 33.1499Z" fill="white" />
                                    <path d="M135.44 41.02C134.52 41.02 133.77 41.77 133.77 42.69C133.77 43.61 134.52 44.36 135.44 44.36C136.36 44.36 137.11 43.61 137.11 42.69C137.11 41.77 136.36 41.02 135.44 41.02Z" fill="white" />
                                    <path d="M143.31 49.72C142.39 49.72 141.64 50.47 141.64 51.39C141.64 52.31 142.39 53.06 143.31 53.06C144.23 53.06 144.98 52.31 144.98 51.39C144.98 50.47 144.23 49.72 143.31 49.72Z" fill="white" />
                                    <path d="M135.44 57.5901C134.52 57.5901 133.77 58.3401 133.77 59.2601C133.77 60.1801 134.52 60.9301 135.44 60.9301C136.36 60.9301 137.11 60.1801 137.11 59.2601C137.11 58.3401 136.36 57.5901 135.44 57.5901Z" fill="white" />
                                    <path d="M143.31 66.3C142.39 66.3 141.64 67.05 141.64 67.97C141.64 68.89 142.39 69.64 143.31 69.64C144.23 69.64 144.98 68.89 144.98 67.97C144.98 67.05 144.23 66.3 143.31 66.3Z" fill="white" />
                                    <path d="M159.05 0C158.13 0 157.38 0.75 157.38 1.67C157.38 2.59 158.13 3.34 159.05 3.34C159.97 3.34 160.72 2.59 160.72 1.67C160.72 0.75 159.97 0 159.05 0Z" fill="white" />
                                    <path d="M151.18 7.87012C150.26 7.87012 149.51 8.62012 149.51 9.54012C149.51 10.4601 150.26 11.2101 151.18 11.2101C152.1 11.2101 152.85 10.4601 152.85 9.54012C152.85 8.62012 152.1 7.87012 151.18 7.87012Z" fill="white" />
                                    <path d="M159.05 16.5701C158.13 16.5701 157.38 17.3201 157.38 18.2401C157.38 19.1601 158.13 19.9101 159.05 19.9101C159.97 19.9101 160.72 19.1601 160.72 18.2401C160.72 17.3201 159.97 16.5701 159.05 16.5701Z" fill="white" />
                                    <path d="M151.18 24.4399C150.26 24.4399 149.51 25.1899 149.51 26.1099C149.51 27.0299 150.26 27.7799 151.18 27.7799C152.1 27.7799 152.85 27.0299 152.85 26.1099C152.85 25.1899 152.1 24.4399 151.18 24.4399Z" fill="white" />
                                    <path d="M159.05 33.1499C158.13 33.1499 157.38 33.8999 157.38 34.8199C157.38 35.7399 158.13 36.4899 159.05 36.4899C159.97 36.4899 160.72 35.7399 160.72 34.8199C160.72 33.8999 159.97 33.1499 159.05 33.1499Z" fill="white" />
                                    <path d="M151.18 41.02C150.26 41.02 149.51 41.77 149.51 42.69C149.51 43.61 150.26 44.36 151.18 44.36C152.1 44.36 152.85 43.61 152.85 42.69C152.85 41.77 152.1 41.02 151.18 41.02Z" fill="white" />
                                    <path d="M159.05 49.72C158.13 49.72 157.38 50.47 157.38 51.39C157.38 52.31 158.13 53.06 159.05 53.06C159.97 53.06 160.72 52.31 160.72 51.39C160.72 50.47 159.97 49.72 159.05 49.72Z" fill="white" />
                                    <path d="M151.18 57.5901C150.26 57.5901 149.51 58.3401 149.51 59.2601C149.51 60.1801 150.26 60.9301 151.18 60.9301C152.1 60.9301 152.85 60.1801 152.85 59.2601C152.85 58.3401 152.1 57.5901 151.18 57.5901Z" fill="white" />
                                    <path d="M159.05 66.3C158.13 66.3 157.38 67.05 157.38 67.97C157.38 68.89 158.13 69.64 159.05 69.64C159.97 69.64 160.72 68.89 160.72 67.97C160.72 67.05 159.97 66.3 159.05 66.3Z" fill="white" />
                                    <path d="M174.78 3.34C175.703 3.34 176.45 2.59232 176.45 1.67C176.45 0.747684 175.703 0 174.78 0C173.858 0 173.11 0.747684 173.11 1.67C173.11 2.59232 173.858 3.34 174.78 3.34Z" fill="white" />
                                    <path d="M166.91 7.87012C165.99 7.87012 165.24 8.62012 165.24 9.54012C165.24 10.4601 165.99 11.2101 166.91 11.2101C167.83 11.2101 168.58 10.4601 168.58 9.54012C168.58 8.62012 167.83 7.87012 166.91 7.87012Z" fill="white" />
                                    <path d="M174.78 16.5701C173.86 16.5701 173.11 17.3201 173.11 18.2401C173.11 19.1601 173.86 19.9101 174.78 19.9101C175.7 19.9101 176.45 19.1601 176.45 18.2401C176.45 17.3201 175.7 16.5701 174.78 16.5701Z" fill="white" />
                                    <path d="M166.91 24.4399C165.99 24.4399 165.24 25.1899 165.24 26.1099C165.24 27.0299 165.99 27.7799 166.91 27.7799C167.83 27.7799 168.58 27.0299 168.58 26.1099C168.58 25.1899 167.83 24.4399 166.91 24.4399Z" fill="white" />
                                    <path d="M174.78 33.1499C173.86 33.1499 173.11 33.8999 173.11 34.8199C173.11 35.7399 173.86 36.4899 174.78 36.4899C175.7 36.4899 176.45 35.7399 176.45 34.8199C176.45 33.8999 175.7 33.1499 174.78 33.1499Z" fill="white" />
                                    <path d="M166.91 41.02C165.99 41.02 165.24 41.77 165.24 42.69C165.24 43.61 165.99 44.36 166.91 44.36C167.83 44.36 168.58 43.61 168.58 42.69C168.58 41.77 167.83 41.02 166.91 41.02Z" fill="white" />
                                    <path d="M174.78 49.72C173.86 49.72 173.11 50.47 173.11 51.39C173.11 52.31 173.86 53.06 174.78 53.06C175.7 53.06 176.45 52.31 176.45 51.39C176.45 50.47 175.7 49.72 174.78 49.72Z" fill="white" />
                                    <path d="M166.91 57.5901C165.99 57.5901 165.24 58.3401 165.24 59.2601C165.24 60.1801 165.99 60.9301 166.91 60.9301C167.83 60.9301 168.58 60.1801 168.58 59.2601C168.58 58.3401 167.83 57.5901 166.91 57.5901Z" fill="white" />
                                    <path d="M174.78 69.64C175.703 69.64 176.45 68.8924 176.45 67.97C176.45 67.0477 175.703 66.3 174.78 66.3C173.858 66.3 173.11 67.0477 173.11 67.97C173.11 68.8924 173.858 69.64 174.78 69.64Z" fill="white" />
                                    <path d="M190.52 0C189.6 0 188.85 0.75 188.85 1.67C188.85 2.59 189.6 3.34 190.52 3.34C191.44 3.34 192.19 2.59 192.19 1.67C192.19 0.75 191.44 0 190.52 0Z" fill="white" />
                                    <path d="M182.65 7.87012C181.73 7.87012 180.98 8.62012 180.98 9.54012C180.98 10.4601 181.73 11.2101 182.65 11.2101C183.57 11.2101 184.32 10.4601 184.32 9.54012C184.32 8.62012 183.57 7.87012 182.65 7.87012Z" fill="white" />
                                    <path d="M190.52 16.5701C189.6 16.5701 188.85 17.3201 188.85 18.2401C188.85 19.1601 189.6 19.9101 190.52 19.9101C191.44 19.9101 192.19 19.1601 192.19 18.2401C192.19 17.3201 191.44 16.5701 190.52 16.5701Z" fill="white" />
                                    <path d="M182.65 24.4399C181.73 24.4399 180.98 25.1899 180.98 26.1099C180.98 27.0299 181.73 27.7799 182.65 27.7799C183.57 27.7799 184.32 27.0299 184.32 26.1099C184.32 25.1899 183.57 24.4399 182.65 24.4399Z" fill="white" />
                                    <path d="M190.52 33.1499C189.6 33.1499 188.85 33.8999 188.85 34.8199C188.85 35.7399 189.6 36.4899 190.52 36.4899C191.44 36.4899 192.19 35.7399 192.19 34.8199C192.19 33.8999 191.44 33.1499 190.52 33.1499Z" fill="white" />
                                    <path d="M182.65 41.02C181.73 41.02 180.98 41.77 180.98 42.69C180.98 43.61 181.73 44.36 182.65 44.36C183.57 44.36 184.32 43.61 184.32 42.69C184.32 41.77 183.57 41.02 182.65 41.02Z" fill="white" />
                                    <path d="M190.52 49.72C189.6 49.72 188.85 50.47 188.85 51.39C188.85 52.31 189.6 53.06 190.52 53.06C191.44 53.06 192.19 52.31 192.19 51.39C192.19 50.47 191.44 49.72 190.52 49.72Z" fill="white" />
                                    <path d="M182.65 57.5901C181.73 57.5901 180.98 58.3401 180.98 59.2601C180.98 60.1801 181.73 60.9301 182.65 60.9301C183.57 60.9301 184.32 60.1801 184.32 59.2601C184.32 58.3401 183.57 57.5901 182.65 57.5901Z" fill="white" />
                                    <path d="M190.52 66.3C189.6 66.3 188.85 67.05 188.85 67.97C188.85 68.89 189.6 69.64 190.52 69.64C191.44 69.64 192.19 68.89 192.19 67.97C192.19 67.05 191.44 66.3 190.52 66.3Z" fill="white" />
                                </svg>
                                <h4 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("SOLUTIONS")}</h4>
                            </div>
                            <span>Starbucks</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="img-slide-four">
                            <h2 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("DRIVEN-BY")}</h2>
                            <div className="flex-aswad-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="193" height="70" viewBox="0 0 193 70" fill="none">
                                    <path d="M1.67 3.34C2.59232 3.34 3.34 2.59232 3.34 1.67C3.34 0.747684 2.59232 0 1.67 0C0.747684 0 0 0.747684 0 1.67C0 2.59232 0.747684 3.34 1.67 3.34Z" fill="white" />
                                    <path d="M1.67 16.5701C0.75 16.5701 0 17.3201 0 18.2401C0 19.1601 0.75 19.9101 1.67 19.9101C2.59 19.9101 3.34 19.1601 3.34 18.2401C3.34 17.3201 2.59 16.5701 1.67 16.5701Z" fill="white" />
                                    <path d="M1.67 33.1499C0.75 33.1499 0 33.8999 0 34.8199C0 35.7399 0.75 36.4899 1.67 36.4899C2.59 36.4899 3.34 35.7399 3.34 34.8199C3.34 33.8999 2.59 33.1499 1.67 33.1499Z" fill="white" />
                                    <path d="M1.67 49.72C0.75 49.72 0 50.47 0 51.39C0 52.31 0.75 53.06 1.67 53.06C2.59 53.06 3.34 52.31 3.34 51.39C3.34 50.47 2.59 49.72 1.67 49.72Z" fill="white" />
                                    <path d="M1.67 69.64C2.59232 69.64 3.34 68.8924 3.34 67.97C3.34 67.0477 2.59232 66.3 1.67 66.3C0.747684 66.3 0 67.0477 0 67.97C0 68.8924 0.747684 69.64 1.67 69.64Z" fill="white" />
                                    <path d="M17.4102 3.34C18.3326 3.34 19.0802 2.59232 19.0802 1.67C19.0802 0.747684 18.3326 0 17.4102 0C16.4879 0 15.7402 0.747684 15.7402 1.67C15.7402 2.59232 16.4879 3.34 17.4102 3.34Z" fill="white" />
                                    <path d="M9.54012 7.87012C8.62012 7.87012 7.87012 8.62012 7.87012 9.54012C7.87012 10.4601 8.62012 11.2101 9.54012 11.2101C10.4601 11.2101 11.2101 10.4601 11.2101 9.54012C11.2101 8.62012 10.4601 7.87012 9.54012 7.87012Z" fill="white" />
                                    <path d="M17.4102 16.5701C16.4902 16.5701 15.7402 17.3201 15.7402 18.2401C15.7402 19.1601 16.4902 19.9101 17.4102 19.9101C18.3302 19.9101 19.0802 19.1601 19.0802 18.2401C19.0802 17.3201 18.3302 16.5701 17.4102 16.5701Z" fill="white" />
                                    <path d="M9.54012 24.4399C8.62012 24.4399 7.87012 25.1899 7.87012 26.1099C7.87012 27.0299 8.62012 27.7799 9.54012 27.7799C10.4601 27.7799 11.2101 27.0299 11.2101 26.1099C11.2101 25.1899 10.4601 24.4399 9.54012 24.4399Z" fill="white" />
                                    <path d="M17.4102 33.1499C16.4902 33.1499 15.7402 33.8999 15.7402 34.8199C15.7402 35.7399 16.4902 36.4899 17.4102 36.4899C18.3302 36.4899 19.0802 35.7399 19.0802 34.8199C19.0802 33.8999 18.3302 33.1499 17.4102 33.1499Z" fill="white" />
                                    <path d="M9.54012 41.02C8.62012 41.02 7.87012 41.77 7.87012 42.69C7.87012 43.61 8.62012 44.36 9.54012 44.36C10.4601 44.36 11.2101 43.61 11.2101 42.69C11.2101 41.77 10.4601 41.02 9.54012 41.02Z" fill="white" />
                                    <path d="M17.4102 49.72C16.4902 49.72 15.7402 50.47 15.7402 51.39C15.7402 52.31 16.4902 53.06 17.4102 53.06C18.3302 53.06 19.0802 52.31 19.0802 51.39C19.0802 50.47 18.3302 49.72 17.4102 49.72Z" fill="white" />
                                    <path d="M9.54012 57.5901C8.62012 57.5901 7.87012 58.3401 7.87012 59.2601C7.87012 60.1801 8.62012 60.9301 9.54012 60.9301C10.4601 60.9301 11.2101 60.1801 11.2101 59.2601C11.2101 58.3401 10.4601 57.5901 9.54012 57.5901Z" fill="white" />
                                    <path d="M17.4102 69.64C18.3326 69.64 19.0802 68.8924 19.0802 67.97C19.0802 67.0477 18.3326 66.3 17.4102 66.3C16.4879 66.3 15.7402 67.0477 15.7402 67.97C15.7402 68.8924 16.4879 69.64 17.4102 69.64Z" fill="white" />
                                    <path d="M33.1505 3.34C34.0728 3.34 34.8205 2.59232 34.8205 1.67C34.8205 0.747684 34.0728 0 33.1505 0C32.2282 0 31.4805 0.747684 31.4805 1.67C31.4805 2.59232 32.2282 3.34 33.1505 3.34Z" fill="white" />
                                    <path d="M25.2804 7.87012C24.3604 7.87012 23.6104 8.62012 23.6104 9.54012C23.6104 10.4601 24.3604 11.2101 25.2804 11.2101C26.2004 11.2101 26.9504 10.4601 26.9504 9.54012C26.9504 8.62012 26.2004 7.87012 25.2804 7.87012Z" fill="white" />
                                    <path d="M33.1505 16.5701C32.2305 16.5701 31.4805 17.3201 31.4805 18.2401C31.4805 19.1601 32.2305 19.9101 33.1505 19.9101C34.0705 19.9101 34.8205 19.1601 34.8205 18.2401C34.8205 17.3201 34.0705 16.5701 33.1505 16.5701Z" fill="white" />
                                    <path d="M25.2804 24.4399C24.3604 24.4399 23.6104 25.1899 23.6104 26.1099C23.6104 27.0299 24.3604 27.7799 25.2804 27.7799C26.2004 27.7799 26.9504 27.0299 26.9504 26.1099C26.9504 25.1899 26.2004 24.4399 25.2804 24.4399Z" fill="white" />
                                    <path d="M33.1505 33.1499C32.2305 33.1499 31.4805 33.8999 31.4805 34.8199C31.4805 35.7399 32.2305 36.4899 33.1505 36.4899C34.0705 36.4899 34.8205 35.7399 34.8205 34.8199C34.8205 33.8999 34.0705 33.1499 33.1505 33.1499Z" fill="white" />
                                    <path d="M25.2804 41.02C24.3604 41.02 23.6104 41.77 23.6104 42.69C23.6104 43.61 24.3604 44.36 25.2804 44.36C26.2004 44.36 26.9504 43.61 26.9504 42.69C26.9504 41.77 26.2004 41.02 25.2804 41.02Z" fill="white" />
                                    <path d="M33.1505 49.72C32.2305 49.72 31.4805 50.47 31.4805 51.39C31.4805 52.31 32.2305 53.06 33.1505 53.06C34.0705 53.06 34.8205 52.31 34.8205 51.39C34.8205 50.47 34.0705 49.72 33.1505 49.72Z" fill="white" />
                                    <path d="M25.2804 57.5901C24.3604 57.5901 23.6104 58.3401 23.6104 59.2601C23.6104 60.1801 24.3604 60.9301 25.2804 60.9301C26.2004 60.9301 26.9504 60.1801 26.9504 59.2601C26.9504 58.3401 26.2004 57.5901 25.2804 57.5901Z" fill="white" />
                                    <path d="M33.1505 69.64C34.0728 69.64 34.8205 68.8924 34.8205 67.97C34.8205 67.0477 34.0728 66.3 33.1505 66.3C32.2282 66.3 31.4805 67.0477 31.4805 67.97C31.4805 68.8924 32.2282 69.64 33.1505 69.64Z" fill="white" />
                                    <path d="M48.8897 0C47.9697 0 47.2197 0.75 47.2197 1.67C47.2197 2.59 47.9697 3.34 48.8897 3.34C49.8097 3.34 50.5597 2.59 50.5597 1.67C50.5597 0.75 49.8097 0 48.8897 0Z" fill="white" />
                                    <path d="M41.0196 7.87012C40.0996 7.87012 39.3496 8.62012 39.3496 9.54012C39.3496 10.4601 40.0996 11.2101 41.0196 11.2101C41.9396 11.2101 42.6896 10.4601 42.6896 9.54012C42.6896 8.62012 41.9396 7.87012 41.0196 7.87012Z" fill="white" />
                                    <path d="M48.8897 16.5701C47.9697 16.5701 47.2197 17.3201 47.2197 18.2401C47.2197 19.1601 47.9697 19.9101 48.8897 19.9101C49.8097 19.9101 50.5597 19.1601 50.5597 18.2401C50.5597 17.3201 49.8097 16.5701 48.8897 16.5701Z" fill="white" />
                                    <path d="M41.0196 24.4399C40.0996 24.4399 39.3496 25.1899 39.3496 26.1099C39.3496 27.0299 40.0996 27.7799 41.0196 27.7799C41.9396 27.7799 42.6896 27.0299 42.6896 26.1099C42.6896 25.1899 41.9396 24.4399 41.0196 24.4399Z" fill="white" />
                                    <path d="M48.8897 33.1499C47.9697 33.1499 47.2197 33.8999 47.2197 34.8199C47.2197 35.7399 47.9697 36.4899 48.8897 36.4899C49.8097 36.4899 50.5597 35.7399 50.5597 34.8199C50.5597 33.8999 49.8097 33.1499 48.8897 33.1499Z" fill="white" />
                                    <path d="M41.0196 41.02C40.0996 41.02 39.3496 41.77 39.3496 42.69C39.3496 43.61 40.0996 44.36 41.0196 44.36C41.9396 44.36 42.6896 43.61 42.6896 42.69C42.6896 41.77 41.9396 41.02 41.0196 41.02Z" fill="white" />
                                    <path d="M48.8897 49.72C47.9697 49.72 47.2197 50.47 47.2197 51.39C47.2197 52.31 47.9697 53.06 48.8897 53.06C49.8097 53.06 50.5597 52.31 50.5597 51.39C50.5597 50.47 49.8097 49.72 48.8897 49.72Z" fill="white" />
                                    <path d="M41.0196 57.5901C40.0996 57.5901 39.3496 58.3401 39.3496 59.2601C39.3496 60.1801 40.0996 60.9301 41.0196 60.9301C41.9396 60.9301 42.6896 60.1801 42.6896 59.2601C42.6896 58.3401 41.9396 57.5901 41.0196 57.5901Z" fill="white" />
                                    <path d="M48.8897 66.3C47.9697 66.3 47.2197 67.05 47.2197 67.97C47.2197 68.89 47.9697 69.64 48.8897 69.64C49.8097 69.64 50.5597 68.89 50.5597 67.97C50.5597 67.05 49.8097 66.3 48.8897 66.3Z" fill="white" />
                                    <path d="M64.6202 0C63.7002 0 62.9502 0.75 62.9502 1.67C62.9502 2.59 63.7002 3.34 64.6202 3.34C65.5402 3.34 66.2902 2.59 66.2902 1.67C66.2902 0.75 65.5402 0 64.6202 0Z" fill="white" />
                                    <path d="M56.7501 7.87012C55.8301 7.87012 55.0801 8.62012 55.0801 9.54012C55.0801 10.4601 55.8301 11.2101 56.7501 11.2101C57.6701 11.2101 58.4201 10.4601 58.4201 9.54012C58.4201 8.62012 57.6701 7.87012 56.7501 7.87012Z" fill="white" />
                                    <path d="M64.6202 16.5701C63.7002 16.5701 62.9502 17.3201 62.9502 18.2401C62.9502 19.1601 63.7002 19.9101 64.6202 19.9101C65.5402 19.9101 66.2902 19.1601 66.2902 18.2401C66.2902 17.3201 65.5402 16.5701 64.6202 16.5701Z" fill="white" />
                                    <path d="M56.7501 24.4399C55.8301 24.4399 55.0801 25.1899 55.0801 26.1099C55.0801 27.0299 55.8301 27.7799 56.7501 27.7799C57.6701 27.7799 58.4201 27.0299 58.4201 26.1099C58.4201 25.1899 57.6701 24.4399 56.7501 24.4399Z" fill="white" />
                                    <path d="M64.6202 33.1499C63.7002 33.1499 62.9502 33.8999 62.9502 34.8199C62.9502 35.7399 63.7002 36.4899 64.6202 36.4899C65.5402 36.4899 66.2902 35.7399 66.2902 34.8199C66.2902 33.8999 65.5402 33.1499 64.6202 33.1499Z" fill="white" />
                                    <path d="M56.7501 41.02C55.8301 41.02 55.0801 41.77 55.0801 42.69C55.0801 43.61 55.8301 44.36 56.7501 44.36C57.6701 44.36 58.4201 43.61 58.4201 42.69C58.4201 41.77 57.6701 41.02 56.7501 41.02Z" fill="white" />
                                    <path d="M64.6202 49.72C63.7002 49.72 62.9502 50.47 62.9502 51.39C62.9502 52.31 63.7002 53.06 64.6202 53.06C65.5402 53.06 66.2902 52.31 66.2902 51.39C66.2902 50.47 65.5402 49.72 64.6202 49.72Z" fill="white" />
                                    <path d="M56.7501 57.5901C55.8301 57.5901 55.0801 58.3401 55.0801 59.2601C55.0801 60.1801 55.8301 60.9301 56.7501 60.9301C57.6701 60.9301 58.4201 60.1801 58.4201 59.2601C58.4201 58.3401 57.6701 57.5901 56.7501 57.5901Z" fill="white" />
                                    <path d="M64.6202 66.3C63.7002 66.3 62.9502 67.05 62.9502 67.97C62.9502 68.89 63.7002 69.64 64.6202 69.64C65.5402 69.64 66.2902 68.89 66.2902 67.97C66.2902 67.05 65.5402 66.3 64.6202 66.3Z" fill="white" />
                                    <path d="M80.3604 0C79.4404 0 78.6904 0.75 78.6904 1.67C78.6904 2.59 79.4404 3.34 80.3604 3.34C81.2804 3.34 82.0304 2.59 82.0304 1.67C82.0304 0.75 81.2804 0 80.3604 0Z" fill="white" />
                                    <path d="M72.4903 7.87012C71.5703 7.87012 70.8203 8.62012 70.8203 9.54012C70.8203 10.4601 71.5703 11.2101 72.4903 11.2101C73.4103 11.2101 74.1603 10.4601 74.1603 9.54012C74.1603 8.62012 73.4103 7.87012 72.4903 7.87012Z" fill="white" />
                                    <path d="M80.3604 16.5701C79.4404 16.5701 78.6904 17.3201 78.6904 18.2401C78.6904 19.1601 79.4404 19.9101 80.3604 19.9101C81.2804 19.9101 82.0304 19.1601 82.0304 18.2401C82.0304 17.3201 81.2804 16.5701 80.3604 16.5701Z" fill="white" />
                                    <path d="M72.4903 24.4399C71.5703 24.4399 70.8203 25.1899 70.8203 26.1099C70.8203 27.0299 71.5703 27.7799 72.4903 27.7799C73.4103 27.7799 74.1603 27.0299 74.1603 26.1099C74.1603 25.1899 73.4103 24.4399 72.4903 24.4399Z" fill="white" />
                                    <path d="M80.3604 33.1499C79.4404 33.1499 78.6904 33.8999 78.6904 34.8199C78.6904 35.7399 79.4404 36.4899 80.3604 36.4899C81.2804 36.4899 82.0304 35.7399 82.0304 34.8199C82.0304 33.8999 81.2804 33.1499 80.3604 33.1499Z" fill="white" />
                                    <path d="M72.4903 41.02C71.5703 41.02 70.8203 41.77 70.8203 42.69C70.8203 43.61 71.5703 44.36 72.4903 44.36C73.4103 44.36 74.1603 43.61 74.1603 42.69C74.1603 41.77 73.4103 41.02 72.4903 41.02Z" fill="white" />
                                    <path d="M80.3604 49.72C79.4404 49.72 78.6904 50.47 78.6904 51.39C78.6904 52.31 79.4404 53.06 80.3604 53.06C81.2804 53.06 82.0304 52.31 82.0304 51.39C82.0304 50.47 81.2804 49.72 80.3604 49.72Z" fill="white" />
                                    <path d="M72.4903 57.5901C71.5703 57.5901 70.8203 58.3401 70.8203 59.2601C70.8203 60.1801 71.5703 60.9301 72.4903 60.9301C73.4103 60.9301 74.1603 60.1801 74.1603 59.2601C74.1603 58.3401 73.4103 57.5901 72.4903 57.5901Z" fill="white" />
                                    <path d="M80.3604 66.3C79.4404 66.3 78.6904 67.05 78.6904 67.97C78.6904 68.89 79.4404 69.64 80.3604 69.64C81.2804 69.64 82.0304 68.89 82.0304 67.97C82.0304 67.05 81.2804 66.3 80.3604 66.3Z" fill="white" />
                                    <path d="M96.0997 0C95.1797 0 94.4297 0.75 94.4297 1.67C94.4297 2.59 95.1797 3.34 96.0997 3.34C97.0197 3.34 97.7697 2.59 97.7697 1.67C97.7697 0.75 97.0197 0 96.0997 0Z" fill="white" />
                                    <path d="M88.2296 7.87012C87.4196 7.87012 86.7496 8.44012 86.5896 9.21012C86.5696 9.32012 86.5596 9.43012 86.5596 9.55012C86.5596 9.67012 86.5696 9.78012 86.5896 9.89012C86.7496 10.6501 87.4196 11.2301 88.2296 11.2301C89.1496 11.2301 89.8996 10.4801 89.8996 9.56012C89.8996 8.64012 89.1496 7.89012 88.2296 7.89012V7.87012Z" fill="white" />
                                    <path d="M96.0997 16.5701C95.1797 16.5701 94.4297 17.3201 94.4297 18.2401C94.4297 19.1601 95.1797 19.9101 96.0997 19.9101C97.0197 19.9101 97.7697 19.1601 97.7697 18.2401C97.7697 17.3201 97.0197 16.5701 96.0997 16.5701Z" fill="white" />
                                    <path d="M88.2296 24.4399C87.4196 24.4399 86.7496 25.0099 86.5896 25.7799C86.5696 25.8899 86.5596 25.9999 86.5596 26.1199C86.5596 26.2399 86.5696 26.3499 86.5896 26.4599C86.7496 27.2199 87.4196 27.7999 88.2296 27.7999C89.1496 27.7999 89.8996 27.0499 89.8996 26.1299C89.8996 25.2099 89.1496 24.4599 88.2296 24.4599V24.4399Z" fill="white" />
                                    <path d="M96.0997 33.1499C95.1797 33.1499 94.4297 33.8999 94.4297 34.8199C94.4297 35.7399 95.1797 36.4899 96.0997 36.4899C97.0197 36.4899 97.7697 35.7399 97.7697 34.8199C97.7697 33.8999 97.0197 33.1499 96.0997 33.1499Z" fill="white" />
                                    <path d="M88.2296 41.02C87.4196 41.02 86.7496 41.59 86.5896 42.36C86.5696 42.47 86.5596 42.58 86.5596 42.7C86.5596 42.82 86.5696 42.93 86.5896 43.04C86.7496 43.8 87.4196 44.38 88.2296 44.38C89.1496 44.38 89.8996 43.63 89.8996 42.71C89.8996 41.79 89.1496 41.04 88.2296 41.04V41.02Z" fill="white" />
                                    <path d="M96.0997 49.72C95.1797 49.72 94.4297 50.47 94.4297 51.39C94.4297 52.31 95.1797 53.06 96.0997 53.06C97.0197 53.06 97.7697 52.31 97.7697 51.39C97.7697 50.47 97.0197 49.72 96.0997 49.72Z" fill="white" />
                                    <path d="M88.2296 57.5901C87.4196 57.5901 86.7496 58.1601 86.5896 58.9301C86.5696 59.0401 86.5596 59.1501 86.5596 59.2701C86.5596 59.3901 86.5696 59.5001 86.5896 59.6101C86.7496 60.3701 87.4196 60.9501 88.2296 60.9501C89.1496 60.9501 89.8996 60.2001 89.8996 59.2801C89.8996 58.3601 89.1496 57.6101 88.2296 57.6101V57.5901Z" fill="white" />
                                    <path d="M96.0997 66.3C95.1797 66.3 94.4297 67.05 94.4297 67.97C94.4297 68.89 95.1797 69.64 96.0997 69.64C97.0197 69.64 97.7697 68.89 97.7697 67.97C97.7697 67.05 97.0197 66.3 96.0997 66.3Z" fill="white" />
                                    <path d="M111.83 0C110.91 0 110.16 0.75 110.16 1.67C110.16 2.59 110.91 3.34 111.83 3.34C112.75 3.34 113.5 2.59 113.5 1.67C113.5 0.75 112.75 0 111.83 0Z" fill="white" />
                                    <path d="M103.97 7.87012C103.05 7.87012 102.3 8.62012 102.3 9.54012C102.3 10.4601 103.05 11.2101 103.97 11.2101C104.89 11.2101 105.64 10.4601 105.64 9.54012C105.64 8.62012 104.89 7.87012 103.97 7.87012Z" fill="white" />
                                    <path d="M111.83 16.5701C110.91 16.5701 110.16 17.3201 110.16 18.2401C110.16 19.1601 110.91 19.9101 111.83 19.9101C112.75 19.9101 113.5 19.1601 113.5 18.2401C113.5 17.3201 112.75 16.5701 111.83 16.5701Z" fill="white" />
                                    <path d="M103.97 24.4399C103.05 24.4399 102.3 25.1899 102.3 26.1099C102.3 27.0299 103.05 27.7799 103.97 27.7799C104.89 27.7799 105.64 27.0299 105.64 26.1099C105.64 25.1899 104.89 24.4399 103.97 24.4399Z" fill="white" />
                                    <path d="M111.83 33.1499C110.91 33.1499 110.16 33.8999 110.16 34.8199C110.16 35.7399 110.91 36.4899 111.83 36.4899C112.75 36.4899 113.5 35.7399 113.5 34.8199C113.5 33.8999 112.75 33.1499 111.83 33.1499Z" fill="white" />
                                    <path d="M103.97 41.02C103.05 41.02 102.3 41.77 102.3 42.69C102.3 43.61 103.05 44.36 103.97 44.36C104.89 44.36 105.64 43.61 105.64 42.69C105.64 41.77 104.89 41.02 103.97 41.02Z" fill="white" />
                                    <path d="M111.83 49.72C110.91 49.72 110.16 50.47 110.16 51.39C110.16 52.31 110.91 53.06 111.83 53.06C112.75 53.06 113.5 52.31 113.5 51.39C113.5 50.47 112.75 49.72 111.83 49.72Z" fill="white" />
                                    <path d="M103.97 57.5901C103.05 57.5901 102.3 58.3401 102.3 59.2601C102.3 60.1801 103.05 60.9301 103.97 60.9301C104.89 60.9301 105.64 60.1801 105.64 59.2601C105.64 58.3401 104.89 57.5901 103.97 57.5901Z" fill="white" />
                                    <path d="M111.83 66.3C110.91 66.3 110.16 67.05 110.16 67.97C110.16 68.89 110.91 69.64 111.83 69.64C112.75 69.64 113.5 68.89 113.5 67.97C113.5 67.05 112.75 66.3 111.83 66.3Z" fill="white" />
                                    <path d="M127.57 0C126.65 0 125.9 0.75 125.9 1.67C125.9 2.59 126.65 3.34 127.57 3.34C128.49 3.34 129.24 2.59 129.24 1.67C129.24 0.75 128.49 0 127.57 0Z" fill="white" />
                                    <path d="M119.7 7.87012C118.78 7.87012 118.03 8.62012 118.03 9.54012C118.03 10.4601 118.78 11.2101 119.7 11.2101C120.62 11.2101 121.37 10.4601 121.37 9.54012C121.37 8.62012 120.62 7.87012 119.7 7.87012Z" fill="white" />
                                    <path d="M127.57 16.5701C126.65 16.5701 125.9 17.3201 125.9 18.2401C125.9 19.1601 126.65 19.9101 127.57 19.9101C128.49 19.9101 129.24 19.1601 129.24 18.2401C129.24 17.3201 128.49 16.5701 127.57 16.5701Z" fill="white" />
                                    <path d="M119.7 24.4399C118.78 24.4399 118.03 25.1899 118.03 26.1099C118.03 27.0299 118.78 27.7799 119.7 27.7799C120.62 27.7799 121.37 27.0299 121.37 26.1099C121.37 25.1899 120.62 24.4399 119.7 24.4399Z" fill="white" />
                                    <path d="M127.57 33.1499C126.65 33.1499 125.9 33.8999 125.9 34.8199C125.9 35.7399 126.65 36.4899 127.57 36.4899C128.49 36.4899 129.24 35.7399 129.24 34.8199C129.24 33.8999 128.49 33.1499 127.57 33.1499Z" fill="white" />
                                    <path d="M119.7 41.02C118.78 41.02 118.03 41.77 118.03 42.69C118.03 43.61 118.78 44.36 119.7 44.36C120.62 44.36 121.37 43.61 121.37 42.69C121.37 41.77 120.62 41.02 119.7 41.02Z" fill="white" />
                                    <path d="M127.57 49.72C126.65 49.72 125.9 50.47 125.9 51.39C125.9 52.31 126.65 53.06 127.57 53.06C128.49 53.06 129.24 52.31 129.24 51.39C129.24 50.47 128.49 49.72 127.57 49.72Z" fill="white" />
                                    <path d="M119.7 57.5901C118.78 57.5901 118.03 58.3401 118.03 59.2601C118.03 60.1801 118.78 60.9301 119.7 60.9301C120.62 60.9301 121.37 60.1801 121.37 59.2601C121.37 58.3401 120.62 57.5901 119.7 57.5901Z" fill="white" />
                                    <path d="M127.57 66.3C126.65 66.3 125.9 67.05 125.9 67.97C125.9 68.89 126.65 69.64 127.57 69.64C128.49 69.64 129.24 68.89 129.24 67.97C129.24 67.05 128.49 66.3 127.57 66.3Z" fill="white" />
                                    <path d="M143.31 0C142.39 0 141.64 0.75 141.64 1.67C141.64 2.59 142.39 3.34 143.31 3.34C144.23 3.34 144.98 2.59 144.98 1.67C144.98 0.75 144.23 0 143.31 0Z" fill="white" />
                                    <path d="M135.44 7.87012C134.52 7.87012 133.77 8.62012 133.77 9.54012C133.77 10.4601 134.52 11.2101 135.44 11.2101C136.36 11.2101 137.11 10.4601 137.11 9.54012C137.11 8.62012 136.36 7.87012 135.44 7.87012Z" fill="white" />
                                    <path d="M143.31 16.5701C142.39 16.5701 141.64 17.3201 141.64 18.2401C141.64 19.1601 142.39 19.9101 143.31 19.9101C144.23 19.9101 144.98 19.1601 144.98 18.2401C144.98 17.3201 144.23 16.5701 143.31 16.5701Z" fill="white" />
                                    <path d="M135.44 24.4399C134.52 24.4399 133.77 25.1899 133.77 26.1099C133.77 27.0299 134.52 27.7799 135.44 27.7799C136.36 27.7799 137.11 27.0299 137.11 26.1099C137.11 25.1899 136.36 24.4399 135.44 24.4399Z" fill="white" />
                                    <path d="M143.31 33.1499C142.39 33.1499 141.64 33.8999 141.64 34.8199C141.64 35.7399 142.39 36.4899 143.31 36.4899C144.23 36.4899 144.98 35.7399 144.98 34.8199C144.98 33.8999 144.23 33.1499 143.31 33.1499Z" fill="white" />
                                    <path d="M135.44 41.02C134.52 41.02 133.77 41.77 133.77 42.69C133.77 43.61 134.52 44.36 135.44 44.36C136.36 44.36 137.11 43.61 137.11 42.69C137.11 41.77 136.36 41.02 135.44 41.02Z" fill="white" />
                                    <path d="M143.31 49.72C142.39 49.72 141.64 50.47 141.64 51.39C141.64 52.31 142.39 53.06 143.31 53.06C144.23 53.06 144.98 52.31 144.98 51.39C144.98 50.47 144.23 49.72 143.31 49.72Z" fill="white" />
                                    <path d="M135.44 57.5901C134.52 57.5901 133.77 58.3401 133.77 59.2601C133.77 60.1801 134.52 60.9301 135.44 60.9301C136.36 60.9301 137.11 60.1801 137.11 59.2601C137.11 58.3401 136.36 57.5901 135.44 57.5901Z" fill="white" />
                                    <path d="M143.31 66.3C142.39 66.3 141.64 67.05 141.64 67.97C141.64 68.89 142.39 69.64 143.31 69.64C144.23 69.64 144.98 68.89 144.98 67.97C144.98 67.05 144.23 66.3 143.31 66.3Z" fill="white" />
                                    <path d="M159.05 0C158.13 0 157.38 0.75 157.38 1.67C157.38 2.59 158.13 3.34 159.05 3.34C159.97 3.34 160.72 2.59 160.72 1.67C160.72 0.75 159.97 0 159.05 0Z" fill="white" />
                                    <path d="M151.18 7.87012C150.26 7.87012 149.51 8.62012 149.51 9.54012C149.51 10.4601 150.26 11.2101 151.18 11.2101C152.1 11.2101 152.85 10.4601 152.85 9.54012C152.85 8.62012 152.1 7.87012 151.18 7.87012Z" fill="white" />
                                    <path d="M159.05 16.5701C158.13 16.5701 157.38 17.3201 157.38 18.2401C157.38 19.1601 158.13 19.9101 159.05 19.9101C159.97 19.9101 160.72 19.1601 160.72 18.2401C160.72 17.3201 159.97 16.5701 159.05 16.5701Z" fill="white" />
                                    <path d="M151.18 24.4399C150.26 24.4399 149.51 25.1899 149.51 26.1099C149.51 27.0299 150.26 27.7799 151.18 27.7799C152.1 27.7799 152.85 27.0299 152.85 26.1099C152.85 25.1899 152.1 24.4399 151.18 24.4399Z" fill="white" />
                                    <path d="M159.05 33.1499C158.13 33.1499 157.38 33.8999 157.38 34.8199C157.38 35.7399 158.13 36.4899 159.05 36.4899C159.97 36.4899 160.72 35.7399 160.72 34.8199C160.72 33.8999 159.97 33.1499 159.05 33.1499Z" fill="white" />
                                    <path d="M151.18 41.02C150.26 41.02 149.51 41.77 149.51 42.69C149.51 43.61 150.26 44.36 151.18 44.36C152.1 44.36 152.85 43.61 152.85 42.69C152.85 41.77 152.1 41.02 151.18 41.02Z" fill="white" />
                                    <path d="M159.05 49.72C158.13 49.72 157.38 50.47 157.38 51.39C157.38 52.31 158.13 53.06 159.05 53.06C159.97 53.06 160.72 52.31 160.72 51.39C160.72 50.47 159.97 49.72 159.05 49.72Z" fill="white" />
                                    <path d="M151.18 57.5901C150.26 57.5901 149.51 58.3401 149.51 59.2601C149.51 60.1801 150.26 60.9301 151.18 60.9301C152.1 60.9301 152.85 60.1801 152.85 59.2601C152.85 58.3401 152.1 57.5901 151.18 57.5901Z" fill="white" />
                                    <path d="M159.05 66.3C158.13 66.3 157.38 67.05 157.38 67.97C157.38 68.89 158.13 69.64 159.05 69.64C159.97 69.64 160.72 68.89 160.72 67.97C160.72 67.05 159.97 66.3 159.05 66.3Z" fill="white" />
                                    <path d="M174.78 3.34C175.703 3.34 176.45 2.59232 176.45 1.67C176.45 0.747684 175.703 0 174.78 0C173.858 0 173.11 0.747684 173.11 1.67C173.11 2.59232 173.858 3.34 174.78 3.34Z" fill="white" />
                                    <path d="M166.91 7.87012C165.99 7.87012 165.24 8.62012 165.24 9.54012C165.24 10.4601 165.99 11.2101 166.91 11.2101C167.83 11.2101 168.58 10.4601 168.58 9.54012C168.58 8.62012 167.83 7.87012 166.91 7.87012Z" fill="white" />
                                    <path d="M174.78 16.5701C173.86 16.5701 173.11 17.3201 173.11 18.2401C173.11 19.1601 173.86 19.9101 174.78 19.9101C175.7 19.9101 176.45 19.1601 176.45 18.2401C176.45 17.3201 175.7 16.5701 174.78 16.5701Z" fill="white" />
                                    <path d="M166.91 24.4399C165.99 24.4399 165.24 25.1899 165.24 26.1099C165.24 27.0299 165.99 27.7799 166.91 27.7799C167.83 27.7799 168.58 27.0299 168.58 26.1099C168.58 25.1899 167.83 24.4399 166.91 24.4399Z" fill="white" />
                                    <path d="M174.78 33.1499C173.86 33.1499 173.11 33.8999 173.11 34.8199C173.11 35.7399 173.86 36.4899 174.78 36.4899C175.7 36.4899 176.45 35.7399 176.45 34.8199C176.45 33.8999 175.7 33.1499 174.78 33.1499Z" fill="white" />
                                    <path d="M166.91 41.02C165.99 41.02 165.24 41.77 165.24 42.69C165.24 43.61 165.99 44.36 166.91 44.36C167.83 44.36 168.58 43.61 168.58 42.69C168.58 41.77 167.83 41.02 166.91 41.02Z" fill="white" />
                                    <path d="M174.78 49.72C173.86 49.72 173.11 50.47 173.11 51.39C173.11 52.31 173.86 53.06 174.78 53.06C175.7 53.06 176.45 52.31 176.45 51.39C176.45 50.47 175.7 49.72 174.78 49.72Z" fill="white" />
                                    <path d="M166.91 57.5901C165.99 57.5901 165.24 58.3401 165.24 59.2601C165.24 60.1801 165.99 60.9301 166.91 60.9301C167.83 60.9301 168.58 60.1801 168.58 59.2601C168.58 58.3401 167.83 57.5901 166.91 57.5901Z" fill="white" />
                                    <path d="M174.78 69.64C175.703 69.64 176.45 68.8924 176.45 67.97C176.45 67.0477 175.703 66.3 174.78 66.3C173.858 66.3 173.11 67.0477 173.11 67.97C173.11 68.8924 173.858 69.64 174.78 69.64Z" fill="white" />
                                    <path d="M190.52 0C189.6 0 188.85 0.75 188.85 1.67C188.85 2.59 189.6 3.34 190.52 3.34C191.44 3.34 192.19 2.59 192.19 1.67C192.19 0.75 191.44 0 190.52 0Z" fill="white" />
                                    <path d="M182.65 7.87012C181.73 7.87012 180.98 8.62012 180.98 9.54012C180.98 10.4601 181.73 11.2101 182.65 11.2101C183.57 11.2101 184.32 10.4601 184.32 9.54012C184.32 8.62012 183.57 7.87012 182.65 7.87012Z" fill="white" />
                                    <path d="M190.52 16.5701C189.6 16.5701 188.85 17.3201 188.85 18.2401C188.85 19.1601 189.6 19.9101 190.52 19.9101C191.44 19.9101 192.19 19.1601 192.19 18.2401C192.19 17.3201 191.44 16.5701 190.52 16.5701Z" fill="white" />
                                    <path d="M182.65 24.4399C181.73 24.4399 180.98 25.1899 180.98 26.1099C180.98 27.0299 181.73 27.7799 182.65 27.7799C183.57 27.7799 184.32 27.0299 184.32 26.1099C184.32 25.1899 183.57 24.4399 182.65 24.4399Z" fill="white" />
                                    <path d="M190.52 33.1499C189.6 33.1499 188.85 33.8999 188.85 34.8199C188.85 35.7399 189.6 36.4899 190.52 36.4899C191.44 36.4899 192.19 35.7399 192.19 34.8199C192.19 33.8999 191.44 33.1499 190.52 33.1499Z" fill="white" />
                                    <path d="M182.65 41.02C181.73 41.02 180.98 41.77 180.98 42.69C180.98 43.61 181.73 44.36 182.65 44.36C183.57 44.36 184.32 43.61 184.32 42.69C184.32 41.77 183.57 41.02 182.65 41.02Z" fill="white" />
                                    <path d="M190.52 49.72C189.6 49.72 188.85 50.47 188.85 51.39C188.85 52.31 189.6 53.06 190.52 53.06C191.44 53.06 192.19 52.31 192.19 51.39C192.19 50.47 191.44 49.72 190.52 49.72Z" fill="white" />
                                    <path d="M182.65 57.5901C181.73 57.5901 180.98 58.3401 180.98 59.2601C180.98 60.1801 181.73 60.9301 182.65 60.9301C183.57 60.9301 184.32 60.1801 184.32 59.2601C184.32 58.3401 183.57 57.5901 182.65 57.5901Z" fill="white" />
                                    <path d="M190.52 66.3C189.6 66.3 188.85 67.05 188.85 67.97C188.85 68.89 189.6 69.64 190.52 69.64C191.44 69.64 192.19 68.89 192.19 67.97C192.19 67.05 191.44 66.3 190.52 66.3Z" fill="white" />
                                </svg>
                                <h4 className={i18n.language == "ar" ? "hero-ar" : "hero-en"}>{t("SOLUTIONS")}</h4>
                            </div>
                            <span>ALSubeih HOSPITAL</span>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default Hero