import "./AboutUs.css"
import { AboutHero, Footer, Header, OurClients } from "../../Sections"


const AboutUs = () => {
    return (
        <>
            <Header />
            <AboutHero />
            <OurClients />

            <Footer />

        </>
    )
}

export default AboutUs